import { Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import TextInfo from 'commons/ArtworkItem/TextInfo';
import { getLabelForOption } from 'configs/Constant';

const ArtworkMedium = ({ artwork, color, type }) => {
  const { size, medium } = artwork || {};

  const language = useSelector((state) => state?.language?.currentLanguage);

  const sizeData =
    size?.height &&
    size?.width &&
    `${size?.height} x ${size?.width} ${size?.depth ? ` x ${size?.depth}` : ''}cm `;

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      spacing={1}
      sx={{ mt: 0.5 }}
    >
      <TextInfo
        color={color}
        type={type}
        value={getLabelForOption(medium, language)}
      />
      <TextInfo
        color={color}
        type={type}
        value={sizeData}
      />
    </Stack>
  );
};

export default ArtworkMedium;
