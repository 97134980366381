import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import API from 'configs/API';
import Routes from 'utils/Route';

const useExhibition = (id, options = {}) => {
  const navigate = useNavigate();

  const {
    data: exhibition,
    error,
    isPending,
    ...query
  } = useQuery({
    queryKey: [API.ARTWORK.EXHIBITION_DETAIL(id)],
    ...options,
  });

  useEffect(() => {
    if (error && error?.error?.response?.status === 404) {
      navigate(Routes.PageNotFound);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (!isPending && !exhibition?.is_owner) {
      navigate(Routes.PageNotFound);
    }
  }, [exhibition, isPending, navigate]);

  return { exhibition, isPending, ...query };
};

export default useExhibition;
