import { Box } from '@mui/material';
import React from 'react';

import GridLayout from 'layouts/GridLayout';

import ArtworkMenuList from '../ArtworkMenuList';
import ExhibitionArtworkGroup from '../ExhibitionArtworkGroup';

const TabArtwork = ({ groups, owner }) => {
  return (
    <Box sx={{ mt: 5 }}>
      <GridLayout
        leftColumnProps={{
          lg: 4,
        }}
        rightColumnProps={{
          lg: 8,
        }}
      >
        <ArtworkMenuList groups={groups} />

        <ExhibitionArtworkGroup
          groups={groups}
          owner={owner}
        />
      </GridLayout>
    </Box>
  );
};

export default TabArtwork;
