import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textTitleSize: {
    fontSize: '3.6rem',
    lineHeight: '122%',
  },
}));

export default useStyles;
