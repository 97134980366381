import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import ArtworkMedium from 'commons/ArtworkMedium';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { formatArtworkTitle } from 'utils/utils';

import useStyles from './styles';

const ExhibitionArtwork = ({ artwork, onRemoveArtwork }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.imageContainer}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={onRemoveArtwork}
        />
        <img
          src={artwork?.images[0]}
          className={classes.image}
        />
      </div>

      <Text
        fontWeightMedium
        numberOfLines={1}
        mt={8}
        type="sm"
      >
        {formatArtworkTitle(artwork)}
      </Text>

      <ArtworkMedium
        color={Colors.Black}
        type="xs"
        artwork={artwork}
      />
    </>
  );
};

export default ExhibitionArtwork;
