import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { TableCell, TableRow, Popover } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';
import Routes from 'utils/Route';

import ArtworkCol from '../../../../../../../../commons/ArtworkCol';
import EditionLocation from '../EditionLocation';
import EditionStatus from '../EditionStatus';
import useDeleteEdition from './hooks/useDeleteEdition';
import useStyles from './styles';

const EditionRow = ({
  artwork,
  edition,
  isEvenRow,
  editionIndex,
  editionsSize,
  statusOption,
  locationOption,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { mutate: deleteEdition } = useDeleteEdition();

  const isFirstEdition = editionIndex === 0;
  const isLastEdition = editionIndex === editionsSize - 1;
  const notHasViewMore = isLastEdition && artwork.editions.length <= 3;
  const editionHasCertificate = !!edition?.id_certificate;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateCertificate = (e) => {
    e.stopPropagation();
    navigate(Routes.CreateCertificate(artwork?.id));
  };

  const handleDeleteEdition = () => {
    deleteEdition(edition?.id);
  };

  const handleShowPopUpConfirmDelete = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setIsShowDialog(true);
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setIsShowDialog(false);
  };

  return (
    <>
      <TableRow
        key={edition?.id}
        className={clsx(classes.tableNoBorder, {
          [classes.tableRowBorder]: notHasViewMore,
          [classes.tableEvenRow]: isEvenRow,
        })}
      >
        {isFirstEdition && (
          <TableCell rowSpan={editionsSize}>
            <ArtworkCol
              id={artwork?.id}
              image={artwork?.image}
              title={artwork?.title}
              content={`${t('edition_volume')}: ${artwork?.editions.length}`}
              isLink={true}
            />
          </TableCell>
        )}
        <TableCell>{edition?.edition_number}</TableCell>
        <TableCell>
          <EditionStatus
            editionId={edition?.id}
            editionStatus={edition?.status}
            statusOption={statusOption}
            editionHasCertificate={editionHasCertificate}
          />
        </TableCell>
        <TableCell>{edition?.id_certificate || '--'}</TableCell>
        <TableCell>{edition?.owner_certificate?.name || '--'}</TableCell>
        <TableCell>
          <EditionLocation
            edition={edition}
            locationOption={locationOption}
          />
        </TableCell>
        <TableCell>
          <div className={classes.wrapIcon}>
            <MoreHorizOutlinedIcon
              onClick={handleClick}
              className={classes.iconOutLine}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              className={classes.popover}
            >
              <Text
                clickAble
                className={clsx(classes.textItemPopover, {
                  [classes.disable]: editionHasCertificate,
                })}
              >
                <Text
                  onClick={handleCreateCertificate}
                  type="ml"
                >
                  {t('create_certificate')}
                </Text>
              </Text>

              <Text
                clickAble
                className={clsx(classes.textItemPopover)}
              >
                {t('create_contract')}
              </Text>

              <Text
                onClick={handleShowPopUpConfirmDelete}
                clickAble
                className={clsx(classes.textItemPopover, classes.textDelete, {
                  [classes.disable]: editionHasCertificate,
                })}
              >
                {t('delete_edition')}
              </Text>
            </Popover>
          </div>
        </TableCell>
      </TableRow>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('delete_artwork')}
        textCancel={t('cancel')}
        textSubmit={t('delete')}
        onCancel={handleCancelDelete}
        onSubmit={handleDeleteEdition}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
        cancelButtonStyle={clsx(classes.buttonDialog)}
      >
        <div>{t('are_you_sure_want_delete_edition')}</div>
      </DialogCustom>
    </>
  );
};

export default EditionRow;
