import { Box, Button, LinearProgress } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { MAX_CREATE_EXHIBITION_STEPS } from 'configs/Constant';

import useStyles from './styles';

const GroupThreeButtonProgress = ({
  step,
  textButtonOne,
  textButtonTwo,
  onClickButtonOne,
  disabledButtonOne,
  onClickButtonTwo,
  disabledButtonTwo,
  marginTopBox,
  marginBottomBox,
  isUpdate,
  linearProgressStyle,
  onClickButtonThree,
  disabledButtonThree,
  textButtonThree,
}) => {
  const classes = useStyles();

  const progressValue = ((step - 1) / MAX_CREATE_EXHIBITION_STEPS) * 100;
  const buttonText = !isUpdate
    ? `${textButtonOne} (${step}/${MAX_CREATE_EXHIBITION_STEPS})`
    : textButtonOne;

  return (
    <>
      {!isUpdate && (
        <LinearProgress
          variant="determinate"
          value={progressValue}
          className={clsx(classes.linearProgress, linearProgressStyle)}
        />
      )}

      <Box
        className={classes.wrapBoxContent}
        sx={{ mt: marginTopBox, mb: marginBottomBox }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={onClickButtonOne}
          disabled={disabledButtonOne}
        >
          {buttonText}
        </Button>

        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={onClickButtonTwo}
            disabled={disabledButtonTwo}
            className={classes.buttonTwo}
          >
            {textButtonTwo}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={onClickButtonThree}
            disabled={disabledButtonThree}
            className={classes.buttonTwo}
          >
            {textButtonThree}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default GroupThreeButtonProgress;
