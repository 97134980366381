import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosDelete } from 'utils/apis/axios';

const deleteExhibition = async (id) => {
  const { data } = await axiosDelete(false, API.ARTWORK.DELETE_EXHIBITION(id));

  return data;
};

const useDeleteExhibition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteExhibition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.EXHIBITION] });
    },
  });
};

export default useDeleteExhibition;
