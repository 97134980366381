import { Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import ImageCustom from 'commons/ImageCustom';
import ErrorImage from 'images/error_image.png';
import Routes from 'utils/Route';

import TitleArtworkCol from './TitleArtworkCol';
import useStyles from './styles';

const ArtworkCol = ({ id, image, title, isLink }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClickArtworkImage = () => {
    if (!isLink) {
      return;
    }

    navigate(Routes.ArtworkDetail(id));
  };

  return (
    <Stack
      direction="row"
      spacing={1}
    >
      <ImageCustom
        src={image}
        imageStyle={classes.image}
        loadingStyle={classes.loadingImage}
        iconLoadingStyle={classes.iconImage}
        errorStyle={classes.errorStyle}
        onClickImageDisplay={handleClickArtworkImage}
        customeError={
          <img
            src={ErrorImage}
            alt="Error image"
            className={clsx(classes.imageError, classes.image)}
            onClick={handleClickArtworkImage}
          />
        }
      />

      <div className={classes.wrapContent}>
        <TitleArtworkCol
          title={title}
          id={id}
          isLink={isLink}
        />
      </div>
    </Stack>
  );
};

export default ArtworkCol;
