import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 10,
  },
  formControl2: {
    marginTop: 16,
  },
  labelStyle: {
    padding: 0,
  },
  wrapDateSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
  },
  wrapGroupButton: {
    marginTop: 16,
  },
  linearProgressStyle: {
    '&.MuiLinearProgress-root': {
      borderTopRightRadius: '0 !important',
      borderTopLeftRadius: '0 !important',
    },
  },
  buttonDialog: {
    '&.MuiButton-root': {
      minWidth: 90,
      padding: '10px 16px',
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 0.64,
    },
  },
  buttonDanger: {
    '&.MuiButton-root': {
      marginLeft: 16,
      background: Colors.Error,
      '&:hover': {
        background: Colors.Error,
      },
    },
  },
}));

export default useStyles;
