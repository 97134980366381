import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import Routes from 'utils/Route';

import ImageExhibition from './ImageExhibition';
import useStyles from './styles';

const ExhibitionItem = ({ exhibition, isOwner }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cover_image, id, is_public, title, is_draft, is_ongoing } = exhibition || {};

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
  }

  const formattedDate = `${formatDate(exhibition.date_start)} - ${formatDate(exhibition.date_end)}`;
  const statusExhibition = is_draft ? t('draft') : is_ongoing ? t('on_going') : '';

  return (
    <Link to={Routes.ExhibitionDetail(exhibition?.id)}>
      <ImageExhibition
        cover={cover_image}
        exhibitionId={id}
        isOwner={isOwner}
        isPublicExhibition={is_public}
      />

      <Text
        type="md"
        color={Colors.Grey10}
        fontWeightMedium
        mt={8}
      >
        {title}
      </Text>

      <div className={classes.wrapDateTime}>
        <Text type="xs">{formattedDate}</Text>
        <Text
          type="md"
          color={is_draft ? Colors.Gray6 : Colors.Grey10}
          fontWeightMedium
        >
          {statusExhibition}
        </Text>
      </div>
    </Link>
  );
};

export default ExhibitionItem;
