import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableColumn from 'commons/TableColumn';

import useStyles from './styles';

const ManageCertificateTableHead = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableColumn
          text={t('#')}
          colStyle={classes.columnMini}
        />

        <TableColumn
          text={t('artwork_name')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('edition_number')}
          colStyle={classes.columnSmall}
        />

        <TableColumn
          text={t('artist')}
          colStyle={classes.columnSmall}
        />

        <TableColumn
          text={t('id_certificate')}
          colStyle={classes.columnMedium}
        />

        <TableColumn
          text={t('issued_date')}
          colStyle={classes.columnSmall}
        />

        <TableColumn
          text={t('updated_date')}
          colStyle={classes.columnSmall}
        />

        <TableColumn
          text={t('processing_status')}
          colStyle={classes.columnMedium}
        />

        <TableColumn
          text={t('action')}
          colStyle={classes.columnSmall}
        />
      </TableRow>
    </TableHead>
  );
};

export default ManageCertificateTableHead;
