import { Box } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';

import TabArtwork from '../TabArtwork';
import TabIntroduction from '../TabIntroduction';
import useStyles from './styles';

const ExhibitionTabs = ({ exhibition }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = Number(searchParams.get('tab') || 1);
  const classes = useStyles();

  const { t } = useTranslation();

  const handleTabChange = (e, newValue) => {
    searchParams.set('tab', newValue);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered
        className={classes.tabs}
      >
        <Tab
          label={t('introduction')}
          value={1}
        />
        <Tab
          label={t('artworks')}
          value={2}
        />
        <Tab
          label={t('artists')}
          value={3}
        />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <TabIntroduction introduction={exhibition?.preface} />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={2}
      >
        <TabArtwork
          groups={exhibition?.groups}
          owner={exhibition?.owner}
        />
      </TabPanel>
    </Box>
  );
};

export default ExhibitionTabs;
