import clsx from 'clsx';
import React from 'react';

import Image from 'commons/Image';

import ExhibitionPopover from '../ExhibitionPopover';
import useStyles from './styles';

const ImageExhibition = ({ cover, exhibitionId, isOwner, isPublicExhibition }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Image
        src={cover}
        className={clsx(classes.image, { [classes.noneImage]: !cover })}
      />

      {isOwner && (
        <ExhibitionPopover
          dotStyle={classes.dotHover}
          exhibitionId={exhibitionId}
          isPublicExhibition={isPublicExhibition}
        />
      )}
    </div>
  );
};

export default ImageExhibition;
