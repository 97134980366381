import { ImageListItem } from '@mui/material';
import React from 'react';

import Image from 'commons/Image';
import Text from 'commons/Text';

import useStyles from './styles';

const ExhibitionArtworkItem = ({ artwork, owner }) => {
  const { title, year_created, period_created, size, images } = artwork || {};
  const classes = useStyles();

  const sizeData =
    size?.height &&
    size?.width &&
    `${size?.height} x ${size?.width} ${size?.depth ? ` x ${size?.depth}` : ''}cm `;

  return (
    <ImageListItem>
      <Image src={images[0]} />

      <Text
        fontWeightBold
        mt={8}
      >
        {title}
        {title && (year_created || period_created) && <span className={classes.spacing}>, </span>}
        {year_created || period_created}
      </Text>

      <Text
        type="sm"
        mt={4}
      >
        {owner?.name}
      </Text>

      <Text
        type="sm"
        mt={4}
      >
        {sizeData}
      </Text>
    </ImageListItem>
  );
};

export default ExhibitionArtworkItem;
