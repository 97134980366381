import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormAutocompleteController from 'commons/FormHook/FormAutocompleteController';
import { EXHIBITION_TYPE_CHOICES } from 'configs/Constant';

import useStyles from './styles';

const ExhibitionTypeChoice = ({ control, name = 'typeOfEvent', disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box sx={{ mt: 2 }}>
      <FormAutocompleteController
        name={name}
        control={control}
        options={EXHIBITION_TYPE_CHOICES}
        label={t('type_of_event')}
        placeholder={t('select_a_status')}
        getOptionLabel={(option) => t(option?.key)}
        isOptionEqualToValue={(option, value) => option.key === value?.key}
        autocompleteStyle={classes.autocompleteMarginTopAdjustment}
        disabled={disabled}
        disableClearable={true}
      />
    </Box>
  );
};

export default ExhibitionTypeChoice;
