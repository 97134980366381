import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  buttonAddGroup: {
    '&.MuiButton-root': {
      marginTop: 16,
      padding: 7,
    },
  },
}));

export default useStyles;
