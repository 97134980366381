import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const commonStylewWapdot = {
  width: 22,
  height: 22,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  borderRadius: 2,
  background: Colors.Grey10,
  zIndex: 2,
};

const useStyles = makeStyles(() => ({
  wrapdots: {
    ...commonStylewWapdot,
    top: 16,
    right: 16,
  },
  wraplock: {
    ...commonStylewWapdot,
    top: 16,
    right: 42,
  },
  iconDots: {
    '&.MuiSvgIcon-root': {
      width: 12,
      height: 12,
      color: Colors.White,
      zIndex: 999,
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      marginTop: 8,
      padding: 8,
    },
  },
  dotActive: {
    opacity: '1 !important',
    visibility: 'visible !important',
  },
}));

export default useStyles;
