import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import ExhibitionItem from 'commons/Exhibition/ExhibitionItem';
import PaginationCustom from 'commons/PaginationCustom';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';

import useStyles from './styles';

const GalleryExhibition = ({ isOwner }) => {
  const classes = useStyles();

  const { artistId } = useParams();
  const [searchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);
  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: artistId,
  };

  const { data, isPending } = useQuery({
    queryKey: [API.ARTWORK.EXHIBITION, params],
  });

  const { page, totalPage, onPageChange } = usePagination(data?.count, data?.page_size);

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const listExhibition = data?.results;

  if (isPending) {
    return;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Grid
        container
        spacing={5}
      >
        {listExhibition?.map((exhibition, index) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={3}
          >
            <ExhibitionItem
              exhibition={exhibition}
              isOwner={isOwner}
            />
          </Grid>
        ))}
      </Grid>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default GalleryExhibition;
