import LockIcon from '@mui/icons-material/Lock';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Popover } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDeleteCollection from 'commons/Collection/hooks/useDeleteCollection';
import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const CollectionPopover = ({ dotStyle, collectionUuid, isPublicCollection }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const { mutate: deleteCollection } = useDeleteCollection();

  const handleOpenPopover = (e) => {
    e?.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e?.preventDefault();
    setAnchorEl(null);
  };

  const handleShowPopUpConfirmDelete = (e) => {
    e?.preventDefault();
    handleClose();
    setIsShowDialog(true);
  };

  const handleDeleteCollection = (e) => {
    e.preventDefault();
    deleteCollection(collectionUuid, {
      onSuccess: () => {
        ToastTopHelper.success(t('delete_success'));
      },
    });
    setIsShowDialog(false);
  };

  const handleCancelDeleteCollection = (e) => {
    e.preventDefault();
    setIsShowDialog(false);
  };

  return (
    <>
      {!isPublicCollection && (
        <div
          className={clsx(
            classes.wraplock,
            {
              [classes.dotActive]: !!anchorEl,
            },
            dotStyle,
          )}
        >
          <LockIcon className={classes.iconDots} />
        </div>
      )}

      <div
        className={clsx(
          classes.wrapdots,
          {
            [classes.dotActive]: !!anchorEl,
          },
          dotStyle,
        )}
        onClick={handleOpenPopover}
      >
        <MoreHorizIcon className={classes.iconDots} />
      </div>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <div className={classes.wrapPopoverContent}>
          <Text
            color={Colors.Error}
            clickAble
            onClick={handleShowPopUpConfirmDelete}
          >
            {t('delete_collection')}
          </Text>
        </div>
      </Popover>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('delete_collection')}
        textCancel={t('cancel')}
        textSubmit={t('delete')}
        onCancel={handleCancelDeleteCollection}
        onSubmit={handleDeleteCollection}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
        cancelButtonStyle={clsx(classes.buttonDialog)}
      >
        {t('are_you_sure_to_delete_this_collection')}
      </DialogCustom>
    </>
  );
};

export default CollectionPopover;
