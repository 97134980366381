const viTranslation = {
  enter_password: 'Nhập mật khẩu',
  phone_number: 'Số điện thoại',
  already_a_member: 'Đã là thành viên?',
  login: 'Đăng nhập',
  username: 'Tên đăng nhập',
  placeholder_username: 'Vui lòng nhập số điện thoại của bạn',
  placeholder_password: 'Vui lòng nhập mật khẩu của bạn',
  placeholder_confirm_password: 'Vui lòng xác nhận mật khẩu của bạn',
  placeholder_name: 'Vui lòng xác nhận tên của bạn',
  placeholder_email: 'Vui lòng xác nhận email của bạn',
  placeholder_phone: 'Vui lòng xác nhận số điện thoại của bạn',
  password: 'Mật khẩu',
  forgot_password: 'Quên mật khẩu?',
  sign_up: 'Đăng ký',
  text_login_policy: 'Bằng cách tiếp tục, bạn đồng ý với điều khoản của GladiusArt',
  and: 'và',
  general_trading_conditions: 'Điều kiện Giao dịch Chung',
  privacy_policy: 'Chính sách Bảo mật',
  login_to_your_account: 'Đăng nhập vào tài khoản của bạn',
  dont_have_an_account: 'Không có tài khoản?',
  update_successfully: 'Cập nhật thành công',
  remove_successfully: 'Xóa thành công',
  name: 'Tên',
  email: 'Email',
  role: 'Vai trò',
  my_account: 'Tài khoản của tôi',
  save_changes: 'Lưu thay đổi',
  cancel: 'Hủy',
  view_profile: 'Xem hồ sơ',
  change_password: 'Đổi mật khẩu',
  notification_preferences: 'Tùy chọn thông báo',
  social_connects: 'Kết nối Mạng xã hội',
  delete_account: 'Xóa tài khoản',
  home: 'Trang chủ',
  gallery: 'Phòng trưng bày',
  my_gallery: 'Phòng trưng bày của tôi',
  all_work: 'Tất cả tác phẩm',
  artist_profile: 'Hồ sơ Nghệ sĩ',
  certificate: 'Chứng nhận',
  upload_your_first_artwork: 'Tải lên tác phẩm đầu tiên của bạn',
  upload_artwork: 'Tải lên Tác phẩm',
  edit_profile: 'Chỉnh sửa Hồ sơ',
  sign_up_new_account: 'Đăng ký tài khoản mới',
  already_member: 'Đã là thành viên?',
  confirm_password: 'Xác nhận mật khẩu',
  invalid_email: 'Vui lòng nhập email hợp lệ',
  invalid_phone: 'Vui lòng nhập số điện thoại hợp lệ',
  invalid_password:
    'Mật khẩu phải mạnh! (ít nhất 1 chữ hoa, 1 chữ thường, 1 số và nhiều hơn 8 ký tự)',
  validate_password_not_match: 'Mật khẩu không khớp',
  registration_successful: 'Đăng ký thành công',
  sign_out: 'Đăng xuất',
  account_setting: 'Cài đặt tài khoản',
  setting: 'Cài đặt',
  help: 'Trợ giúp',
  login_in: 'Đăng nhập',
  follow: 'Theo dõi',
  message: 'Tin nhắn',
  artist_statement: 'Quan điểm nghệ thuật',
  collections: 'Bộ sưu tập',
  artworks: 'Tác phẩm',
  followers: 'Người theo dõi',
  text_guide_add_statement:
    'Bạn có thể chạm vào biểu tượng chỉnh sửa để bắt đầu thêm quan điểm nghệ thuật của mình ở đây.',
  text_guide_add_statement_collector:
    "Đây là nơi nhà sưu tập có thể giới thiệu, chia sẻ về nội dung của bộ sưu tập, quan điểm sưu tập, tầm nhìn và thông điệp của mình, nhấn vào nút '+' để cập nhật statement của nhà sưu tập ở đây",
  text_guide_add_statement_artist:
    "Đây là nơi nghệ sĩ có thể chia sẻ về quan điểm, tư tưởng thực hành nghệ thuật, nguồn cảm hứng, tầm nhìn và thông điệp của nghệ sĩ, nhấn vào nút '+' để cập nhật statement của nghệ sĩ ở đây",
  place_holder_statement: 'Viết quan điểm nghệ thuật của bạn ở đây. Tối đa 200 ký tự.',
  see_more: 'Xem thêm',
  add_a_banner: 'Thêm một banner',
  text_banner_size: 'Kích thước tối ưu 1440 x 160',
  art_vault_version: 'Phiên bản GladiusArt',
  remove: 'Xóa',
  replace_banner_image: 'Thay thế hình ảnh banner',
  maximum_200_characters: 'Cho phép tối đa 200 ký tự',
  update_failed: 'Cập nhật thất bại',
  title: 'Tiêu đề',
  category: 'Danh mục',
  your_images_will_be_up_here: 'Hình ảnh của bạn sẽ được đăng tải ở đây',
  click_to_upload_image:
    'Nhấp vào + để bắt đầu tải lên ảnh bìa và các hình ảnh hỗ trợ khác của bạn',
  text_drag_and_drop_image: 'Bạn có thể kéo và thả một hình ảnh, hoặc',
  browse: 'Duyệt',
  text_choose_category: 'Bạn sẽ phân loại tác phẩm nghệ thuật này như thế nào?',
  next: 'Tiếp theo',
  create_successfully: 'Tạo thành công',
  submit: 'Gửi',
  select_a_status: 'Chọn một trạng thái',
  status: 'Trạng thái',
  artwork_title: 'Tiêu đề tác phẩm',
  give_your_artwork_title: 'Đặt tiêu đề cho tác phẩm nghệ thuật của bạn',
  write_your_description_about_the_artwork: 'Viết mô tả về tác phẩm của bạn...',
  write_your_note_about_the_artwork: 'Viết ghi chú về tác phẩm của bạn...',
  description: 'Mô tả',
  creation_description: 'Mô tả sáng tạo',
  note: 'Ghi chú',
  subject: 'Chủ đề',
  select_subject: 'Chọn một chủ đề',
  color: 'Màu sắc',
  select_main_colors_your_artwork: 'Chọn màu sắc chính của tác phẩm nghệ thuật của bạn',
  select_style_your_artwork: 'Chọn phong cách của tác phẩm nghệ thuật của bạn',
  select_medium_on_material: 'Chọn một chất liệu',
  style: 'Phong cách',
  orientation: 'Hướng',
  size: 'Kích thước',
  length: 'Chiều dài',
  enter_size_of_artwork: 'Nhập {{size}} của tác phẩm nghệ thuật',
  width: 'Chiều rộng',
  depth: 'Chiều sâu',
  wight: 'Trọng lượng',
  image_could_not_be_loaded: 'Hình ảnh không thể được tải',
  artwork_upload: 'Tải lên tác phẩm',
  bulk_upload: 'Tải lên hàng loạt',
  new_collection: 'Bộ sưu tập mới',
  upload_file_failed: 'Tải lên tệp thất bại',
  create_artwork_failed: 'Tạo tác phẩm nghệ thuật thất bại',
  show_less: 'Hiển thị ít hơn',
  visibility: 'Khả năng hiển thị',
  text_art_work_not_visible: 'Tác phẩm nghệ thuật không hiển thị trên Hồ sơ Nghệ sĩ của bạn',
  text_art_work_display_or_hide:
    'Bạn có thể chọn hiển thị hoặc ẩn tác phẩm nghệ thuật này trên Hồ sơ Nghệ sĩ của bạn.',
  text_art_work_auto_hide_and_update:
    'Tác phẩm nghệ thuật tự động ẩn và cập nhật thành “Không bán” trong thời gian hợp lệ.',
  text_art_work_automatic_back_status:
    'Khi thời gian cho thuê hết hạn, nó tự động quay trở lại trạng thái trước đó.',
  display_artwork: 'Hiển thị tác phẩm nghệ thuật',
  rental_period: 'Thời gian cho thuê',
  from: 'Từ',
  to: 'Đến',
  this_artwork_only_shows_for_me: 'Tác phẩm nghệ thuật này chỉ hiển thị cho tôi.',
  hide_price_only_show_to_me: 'Ẩn giá, chỉ hiển thị cho tôi',
  the_artwork_is_public_for_everyone: 'Tác phẩm nghệ thuật là công khai cho mọi người.',
  price: 'Giá',
  enter_price: 'Nhập giá',
  currency: 'Tiền tệ',
  display_price: 'Hiển thị giá',
  exhibition_period: 'Thời gian triển lãm',
  the_artworks_is_public_for_everyone:
    'Các tác phẩm nghệ thuật là công khai cho mọi người và tự động cập nhật thành “Có sẵn để bán” khi thời gian triển lãm hết hạn.',
  private: 'Riêng tư',
  everyone: 'Mọi người',
  public_price_for_everyone: 'Giá công khai cho mọi người',
  artist: 'Nghệ sĩ',
  medium: 'Chất liệu',
  galleries: 'Phòng trưng bày',
  artwork_detail: 'Chi tiết tác phẩm',
  about_the_artwork: 'Về tác phẩm nghệ thuật',
  day_of_creation: 'Ngày tạo ra',
  materials: 'Vật liệu',
  inquire: 'Yêu cầu thông tin',
  medium_on_material: 'Chất liệu',
  creation_year: 'Năm sáng tạo',
  show_creation_year_as_period: 'Hiển thị năm sáng tạo dưới dạng khoảng thời gian',
  before_20th_century: 'ví dụ: Trước thế kỷ 20',
  edition_number: 'Số ấn bản',
  platform_can_not_sort_by_period:
    'Lưu ý: nền tảng chưa hỗ trợ tìm kiếm tác phẩm theo khoảng thời gian',
  image_list_is_empty: 'Danh sách hình ảnh trống. Vui lòng thêm hình ảnh.',
  status_is_required: 'Trạng thái là bắt buộc. Vui lòng nhập trạng thái.',
  title_is_required: 'Tiêu đề là bắt buộc. Vui lòng nhập tiêu đề.',
  length_is_required: 'Chiều cao là bắt buộc. Vui lòng nhập chiều cao.',
  width_is_required: 'Chiều rộng là bắt buộc. Vui lòng nhập chiều rộng.',
  creation_year_is_required: 'Năm sáng tạo là bắt buộc',
  medium_is_required: 'Chất liệu là bắt buộc',
  text_standard_resolution: 'Độ phân giải tiêu chuẩn của hình ảnh Full HD là 1920x1080px.',
  text_upload_max_5_image:
    'Bạn có thể tải lên tối đa 5 hình ảnh của tác phẩm nghệ thuật. Hình ảnh đầu tiên của bạn là ảnh bìa tác phẩm của bạn.',
  front: 'Mặt trước',
  back: 'Trở lại',
  detail: 'Chi tiết',
  signature: 'Chữ ký',
  damage: 'Hư hại',
  create_new_artwork_location: 'Tạo vị trí tác phẩm mới',
  location_name: 'Tên vị trí',
  please_input_location_name: 'Vui lòng nhập tên vị trí',
  select_location: 'Chọn vị trí',
  artwork_location: 'Vị trí lưu trữ',
  selecting_creation_year_not_be_greater_than_current_year:
    'Chọn năm sáng tạo không được lớn hơn năm hiện tại.',
  the_location_name_already_exists: 'Tên vị trí đã tồn tại, vui lòng chọn tên khác.',
  create_award_failed: 'Tạo giải thưởng thất bại',
  year: 'Năm',
  edit_artist_profile: 'Chỉnh sửa Hồ sơ Nghệ sĩ',
  basic_information: 'Thông tin cơ bản',
  artist_nickname: 'Biệt danh Nghệ sĩ',
  enter_your_fullname: 'Nhập họ tên đầy đủ của bạn',
  live_at: 'Sống tại',
  write_anything: 'Viết bất cứ điều gì...',
  enter_your_email: 'Nhập email của bạn',
  enter_your_phone_number: 'Nhập số điện thoại của bạn',
  address: 'Địa chỉ',
  enter_your_address: 'Nhập địa chỉ của bạn',
  website: 'Trang web',
  enter_an_url: 'Nhập một URL',
  add_website: 'Thêm trang web',
  social_links: 'Liên kết Mạng xã hội',
  url: 'Url',
  add_social_link: 'Thêm liên kết mạng xã hội',
  text_description_artist_statement:
    'Bạn có thể sử dụng lời quan điểm nghệ thuật của nghệ sĩ để mô tả về phong cách nghệ thuật của bạn trong các khoảng thời gian khác nhau',
  text_placeholder_about_artist: 'Mô tả ngắn gọn về bản thân',
  about_artist: 'Về Nghệ sĩ',
  artist_statements: 'Quan điểm nghệ thuật',
  enter_a_year: 'Nhập một năm',
  write_something_to_describe: 'Viết điều gì đó để mô tả...',
  year_of_birth_and_hometown: 'Năm sinh và quê quán',
  artist_biography: 'Tiểu sử Nghệ sĩ',
  add_training_background: 'Thêm lý lịch đào tạo',
  training_background: 'Lý lịch đào tạo',
  text_add_detail_training_background:
    'Nếu bạn là một nghệ sĩ tự học, vui lòng cũng điền vào năm bạn bắt đầu và cung cấp chi tiết về nó trong mô tả.',
  membership: 'Hội viên',
  add_membership: 'Thêm hội viên',
  enter_year: 'Nhập năm',
  upload_media: 'Tải lên phương tiện',
  public_on_profile: 'Công khai trên hồ sơ',
  add: 'Thêm',
  you_can_upload_file_with_format:
    'Bạn có thể tải lên tối đa 5 file có định dạng .jpg, .png, .jpeg, .pdf. Kích thước tệp dưới 200 MB.',
  awards: 'Giải thưởng',
  added_events_will_automatically:
    'Các sự kiện được thêm vào sẽ tự động được sắp xếp theo thứ tự thời gian khi được hiển thị trên trang hồ sơ của bạn',
  invalid_website: 'Trang web không hợp lệ',
  invalid_url: 'URL không hợp lệ',
  text_recommend_use_portrait: 'Chúng tôi khuyên bạn nên sử dụng chân dung của mình.',
  upload: 'Tải lên',
  text_confirm_delete: 'Bạn có chắc chắn muốn xóa không?',
  delete_success: 'Xóa thành công',
  delete_failed: 'Xóa thất bại',
  edit_success: 'Chỉnh sửa thành công',
  edit_failed: 'Chỉnh sửa thất bại',
  please_enter_correct_link: 'Vui lòng nhập liên kết chính xác',
  please_enter_correct_year: 'Vui lòng nhập năm chính xác',
  please_select_social_platform: 'Vui lòng chọn nền tảng mạng xã hội',
  please_typing_url: 'Vui lòng nhập URL',
  please_typing_year: 'Vui lòng nhập năm',
  solo_exhibitions: 'Triển lãm cá nhân',
  group_exhibitions: 'Triển lãm nhóm',
  publications: 'Xuất bản',
  lives_and_works_in: 'Sống và làm việc tại',
  contact: 'Liên hệ',
  about: 'Về',
  biography: 'Tiểu sử',
  are_you_sure_you_want_delete_your: 'Bạn có chắc chắn muốn xóa {{name}} của bạn không?',
  edit_solo_exhibition: 'Chỉnh sửa Triển lãm Cá nhân',
  edit_group_exhibition: 'Chỉnh sửa Triển lãm Nhóm',
  edit_publication: 'Chỉnh sửa Xuất bản',
  edit_award: 'Chỉnh sửa Giải thưởng',
  special_works: 'Tác phẩm đặc biệt',
  delete_publication: 'Xóa Xuất bản',
  delete_exhibition: 'Xóa Triển lãm',
  delete_award: 'Xóa Giải thưởng',
  artists: 'Nghệ sĩ',
  search_artist_name: 'Tìm kiếm theo tên nghệ sĩ...',
  error_download_pdf_file: 'Lỗi tải xuống tệp PDF',
  view_artist_profile: 'Xem hồ sơ nghệ sĩ',
  text_max_length_error: 'Vượt quá độ dài ký tự tối đa của {{length}}',
  text_placeholder_place_of_birth: 'Nhập nơi sinh của bạn (ví dụ: Sinh tại Hà Nội)',
  text_placeholder_membership: 'Nhập nơi bạn là hội viên (ví dụ: Thành viên của Nhóm Bloomsbury)',
  text_placeholder_training_background:
    'Nhập lý lịch đào tạo của bạn (ví dụ: Tự học/ Tốt nghiệp từ...)',
  year_of_birth: 'Năm sinh',
  place_of_birth: 'Nơi sinh',
  shipping_info: 'Thông tin vận chuyển',
  text_certificate_delivery:
    'Một chứng nhận vật lý sẽ được giao cho bạn trong <strong>10 ngày</strong>. Vui lòng kiểm tra thông tin vận chuyển của bạn trước khi gửi biểu mẫu này.',
  request_certificate: 'Yêu cầu Chứng nhận',
  exhibition: 'Triển lãm',
  certificate_authenticity: 'Chứng nhận Xác thực',
  this_certifies_the_artwork_presented_below:
    'Tôi ký tên dưới đây, <strong>{{name}}</strong> xác nhận rằng tác phẩm nghệ thuật được trình bày dưới đây là một tác phẩm gốc. Chứng chỉ này được phát hành vào <strong>{{timeDay}}</strong> bởi {{name}} và bảo đảm tính chính xác của các chi tiết và mô tả.',
  this_certificate_belong_to: 'Chứng nhận này thuộc về <strong>{{name}}</strong>',
  text_certificate_owner_info: 'Không có tài khoản, sinh năm {{year}}, Địa chỉ {{address}}',
  text_certificate_contract_number: ', theo số hợp đồng giao dịch {{contractNumber}}.',
  only_the_current_owner:
    'Lưu ý: Chỉ chủ sở hữu hiện tại mới được phép chuyển chứng nhận này sang quyền sở hữu mới, vui lòng liên hệ GladiusArt để được hỗ trợ.',
  the_information_in_the_certificate:
    'Lưu ý quan trọng: Thông tin trong chứng nhận, một khi đã được phát hành thành công, không thể thay đổi nữa. Vui lòng kiểm tra thông tin cẩn thận trước khi tiến hành bước tiếp theo.',
  original_certificate: 'Chứng chỉ Gốc',
  edition: 'Ấn bản',
  artist_signature: 'Chữ ký của Nghệ sĩ',
  property_rights: 'Quyền sở hữu',
  only_the_current_owner_authorized:
    'Lưu ý: Chỉ chủ sở hữu hiện tại mới được phép chuyển chứng nhận này sang quyền sở hữu mới, vui lòng liên hệ GladiusArt để được hỗ trợ.',
  artwork_information: 'Thông tin Tác phẩm',
  owner_information: 'Thông tin Chủ sở hữu',
  artist_name: 'Tên Nghệ sĩ',
  owner_name: 'Tên chủ sở hữu',
  contract_number: 'Số hợp đồng',
  please_enter: 'Vui lòng nhập...',
  account_id: 'ID Tài khoản',
  unique: 'Độc nhất',
  identity_card_number: 'Số CMND/CCCD',
  total_edition_number_is_required: 'Số lượng ấn bản tổng cộng là bắt buộc',
  total_edition_number_not_greater_than: 'Số lượng ấn bản tổng cộng không được vượt quá 20',
  request_create_certificate_successfully: 'Yêu cầu tạo chứng nhận thành công',
  certificate_of_authenticity: 'Chứng chỉ xác thực',
  issued_on_gladius_art: 'Phát hành trên GladiusArt',
  period_year: 'Năm',
  period_created_must_be_at_most_50_characters: 'Thời kỳ tạo ra phải ít hơn 50 ký tự',
  measurement: 'Kích thước',
  does_artwork_have_more_than_one_section: 'Tác phẩm có nhiều hơn một phần không?',
  yes: 'Có',
  no: 'Không',
  enter: 'Nhập',
  please_input_total_sections: 'Vui lòng nhập tổng số phần (ít nhất 2 và không quá 10)',
  total_sections: 'Tổng số phần',
  please_select_your_artwork_has_more_than_one_section:
    'Vui lòng chọn liệu tác phẩm của bạn có nhiều hơn một phần không?',
  add_signature: 'Thêm chữ ký',
  welcome_to: 'Chào mừng đến với',
  please_login_your_account_to_view_more:
    'Vui lòng đăng nhập tài khoản của bạn để xem thêm chi tiết',
  select_image: 'Chọn hình ảnh',
  upload_your_handwritten_signature_image: 'Tải lên hình ảnh chữ ký viết tay của bạn',
  select_your_handwritten: 'Nhấp vào nút để tải lên',
  by_signing_this_certificate:
    'Bằng cách ký vào chứng nhận này với chữ ký điện tử, tôi đồng ý rằng chữ ký sẽ có giá trị như chữ ký viết tay theo quy định của pháp luật.',
  preview: 'Xem trước',
  example: 'Ví dụ',
  we_recommend_that_you_sign_on:
    'Chúng tôi khuyên bạn nên viết tên mình trên tờ giấy trắng lớn với bút mực đen, đảm bảo chữ ký chiếm hết cả trang. Sử dụng điện thoại thông minh, máy tính bảng hoặc máy quét tại nhà để chụp hình ảnh rõ ràng, không phản chiếu và rõ nét của chữ ký của bạn.',
  select_edition_number: 'Chọn số ấn bản',
  change_signature: 'Thay đổi chữ ký',
  height_is_required: 'Chiều cao là bắt buộc',
  manage_editions: 'Quản lý Ấn bản',
  search_by_artwork_name: 'Tìm kiếm theo tên tác phẩm',
  search_by_collection_name: 'Tìm kiếm theo tên bộ sưu tập',

  artwork_name: 'Tên tác phẩm',
  id_certificate: 'ID Chứng chỉ',
  owner: 'Chủ sở hữu',
  location: 'Vị trí',
  contract_validate: 'Hợp đồng có hiệu lực',
  your_signature: 'Chữ ký của bạn',
  show_more: 'Xem thêm',
  edition_volume: 'Số lượng ấn bản',
  more_editions: '{{number}} ấn bản khác',
  manage_certificates: 'Quản lý Chứng chỉ',
  issued_date: 'Ngày phát hành',
  edition_of_certificate: 'Ấn bản của Chứng chỉ',
  updated_date: 'Ngày cập nhật',
  processing_status: 'Trạng thái xử lý',
  action: 'Hành động',
  view_certificate: 'Xem chứng nhận',
  check_log: 'Kiểm tra nhật ký',
  request_admin_help: 'Yêu cầu trợ giúp từ quản trị viên',
  please_select: 'Vui lòng chọn',
  which_best_describes_you: 'Điều nào mô tả bạn nhất?',
  collector: 'Người sưu tập',
  activity_log: 'Nhật ký hoạt động',
  enter_an_artist_id_or_name: 'Nhập ID hoặc tên nghệ sĩ',
  please_select_the_artist: 'Vui lòng chọn nghệ sĩ',
  request_to: 'Yêu cầu đến',
  recipient: 'Người nhận',
  text_act_button_next: 'Tiếp theo: Xem trước Chứng chỉ',
  save_as_draft: 'Lưu nháp',
  choose_account_type: 'Chọn loại tài khoản',
  if_you_need_more_info: 'Nếu bạn cần thêm thông tin, vui lòng kiểm tra trang Trợ giúp.',
  continue: 'Tiếp tục',
  please_choose_account_type: 'Vui lòng chọn loại tài khoản',
  non_account_artist: 'Nghệ sĩ không có tài khoản',
  save_and_send_request: 'Lưu và gửi yêu cầu',
  attachments: 'Tệp đính kèm',
  upload_files: 'Tải tệp lên',
  you_can_upload_maximum_5_files:
    'Bạn có thể tải lên tối đa 5 tệp bao gồm .jpg, .png, .jpeg, .pdf.',
  please_select_request_to: 'Vui lòng chọn yêu cầu đến',
  please_upload_image: 'Vui lòng tải lên hình ảnh',
  issued_certificates: 'Chứng chỉ đã phát hành',
  requested_certificate: 'Chứng chỉ được yêu cầu',
  requesting_certificate: 'Đang yêu cầu Chứng chỉ',
  artist_gallery_name: 'Tên Nghệ sĩ/Phòng trưng bày',
  review_certificate_request: 'Xem xét Yêu cầu Chứng chỉ',
  requested_date: 'Ngày yêu cầu',
  approve_request: 'Chấp nhận yêu cầu',
  reject_request: 'Từ chối yêu cầu',
  cancel_request: 'Hủy yêu cầu',
  yes_cancel: 'Có, hủy',
  are_you_sure_exit_certificate: 'Bạn có chắc chắn muốn thoát khỏi yêu cầu này?',
  send_certificate_request: 'Gửi Yêu cầu Chứng chỉ',
  are_you_sure_send_certificate_request:
    'Bạn có chắc chắn muốn gửi yêu cầu chứng nhận của mình đến {{artistName}} không?',
  request_certificate_success: 'Yêu cầu chứng nhận thành công',
  approve_success: 'Phê duyệt thành công',
  please_select_signature: 'Vui lòng chọn chữ ký',
  reject_reason: 'Lý do từ chối',
  text_placeholder_reject_certificate_request:
    'Vui lòng cung cấp lý do tại sao bạn muốn từ chối yêu cầu chứng nhận này...',
  reject_success: 'Từ chối thành công',
  text_confirm_approve_certificate_request:
    'Bạn có chắc chắn muốn chấp nhận yêu cầu chứng nhận từ <strong>{{colectorName}}</strong> không?',
  owned_certificates: 'Chứng chỉ sở hữu',
  edit_artwork: 'Chỉnh sửa Tác phẩm',
  delete_artwork: 'Xóa Tác phẩm',
  please_select_main_image: 'Vui lòng chọn hình ảnh chính',
  text_confirm_delete_artwork_no_certificate:
    'Tất cả hình ảnh và thông tin của bạn sẽ được gỡ bỏ vĩnh viễn và bạn sẽ không thể xem lại chúng.',
  text_confirm_delete_artwork_has_certificate:
    'Tác phẩm này đã tạo chứng nhận, bạn chỉ được phép ẩn ảnh và trang chi tiết của nó như một tác phẩm riêng tư và bạn chỉ có thể xem chúng trong Quản lý Lưu trữ của mình.',
  text_delete_artwork_success: 'Bạn đã xóa tác phẩm thành công.',
  text_move_artwork_to_store:
    'Tác phẩm của bạn đã được chuyển thành công vào Quản lý Lưu trữ của bạn.',
  create_certificate: 'Tạo chứng nhận',
  artwork_cover: 'Ảnh bìa Tác phẩm',
  back_to_manage_certificate: 'Quay lại Quản lý Chứng chỉ',
  edit_certificate_request: 'Chỉnh sửa Yêu cầu Chứng chỉ',
  request_rejected: 'Yêu cầu bị từ chối',
  back_side: 'Mặt sau',
  select_year: 'Chọn năm',
  update: 'Cập nhật',
  available: 'Có sẵn để bán',
  sold: 'Đã bán',
  not_for_sale: 'Không bán',
  lost: 'Lạc mất',
  donated_gifted: 'Đã tặng/Quà tặng',
  under_maintenance: 'Đang bảo trì',
  consignment: 'Kí gửi',
  download_pdf: 'Tải PDF',
  share: 'Chia sẻ',
  gallaries: 'Phòng trưng bày',
  public_price_for_eveyone: 'Giá công khai cho mọi người',
  request_received: 'Đã nhận được yêu cầu',
  request_approved: 'Yêu cầu được chấp thuận',
  request_denied: 'Yêu cầu bị từ chối',
  vietnamese: 'Tiếng Việt',
  english: 'Tiếng Anh',
  text_cetificate_delivery:
    'Một chứng nhận vật lý sẽ được gửi đến bạn trong <strong>10 ngày</strong>. Vui lòng kiểm tra thông tin giao hàng của bạn trước khi gửi biểu mẫu này.',
  validate_form: 'Bằng việc xác nhận biểu mẫu này:',
  acknowledge_responsibilities:
    'Tôi nhận thức đầy đủ trách nhiệm của mình về tính xác thực của tác phẩm đã khai báo.',
  informed_artcertificate_role:
    'Tôi được thông báo rằng Artcertificate không chịu trách nhiệm về tính xác thực của các tác phẩm được đăng trên trang web của mình, mà chỉ giữ vai trò hướng dẫn trong việc soạn thảo và in ấn chứng nhận xác thực.',
  warned_false_declaration:
    'Tôi đã được cảnh báo về hậu quả trong trường hợp khai báo sai sự thật hoặc cố gắng làm giả chứng nhận xác thực; bất kỳ ai vi phạm các điều khoản của Nghị định Marcus sẽ phải chịu mức phạt dành cho các vi phạm loại năm; điều này có thể dẫn đến việc hủy bán và thanh toán bồi thường thiệt hại.',
  terms_conditions_agreement: 'Tôi đã đọc và đồng ý với Điều khoản và Điều kiện của Gladius Art.',
  authorize_display_photo:
    'Tôi ủy quyền cho Artcertificate hiển thị ảnh tác phẩm trên trang web ở định dạng thu gọn và có dấu nước để người mua có thể kiểm tra xem tác phẩm của họ có khớp với ảnh đã đăng ký khi chứng nhận được tạo hay không. Trách nhiệm pháp lý của Chứng chỉ nghệ thuật: Artcertificate không tham gia dưới bất kỳ hình thức nào trách nhiệm của mình liên quan đến tính xác thực của tác phẩm được ghi lại trên trang web của mình; trách nhiệm này hoàn toàn thuộc về cá nhân hoặc pháp nhân sử dụng dịch vụ của Artcertificate để soạn thảo giấy chứng nhận tính xác thực của nó.',
  validate: 'Xác thực',
  replace: 'Thay thế',
  approve: 'Phê duyệt',
  reject: 'Từ chối',
  painting: 'Tranh vẽ',
  sculpture: 'Điêu khắc',
  other: 'Khác',
  save: 'Lưu',
  link_has_been_copied: 'Link đã được copy',
  this_field_is_require: 'Trường này là bắt buộc',
  site_language: 'Ngôn ngữ trang web',
  en_english: 'EN - Tiếng Anh',
  vi_vietnamese: 'VN - Tiếng Việt',
  language_setting: 'Cài đặt ngôn ngữ',
  search_artwork_or_artist_name: 'Tìm kiếm theo tác phẩm nghệ thuật hoặc tên nghệ sĩ',
  editions: 'Phiên bản',
  certificates: 'Chứng nhận',
  manage_storage: 'Thư viện lưu trữ',
  manage_artworks: 'Quản lý tác phẩm',
  filtered: 'Bộ lọc',
  view_detail: 'Xem chi tiết',
  clear_all: 'Xóa tất cả',
  min: 'Tối thiểu',
  max: 'Tối đa',
  apply: 'Áp dụng',
  enter_min_price: 'Nhập giá tối thiểu',
  enter_max_price: 'Nhập giá tối đa',
  create: 'Tạo mới',
  account: 'Tài khoản',
  exhibition_link: 'Liên kết triển lãm',
  matching_result_found: 'Đã tìm thấy kết quả phù hợp',
  we_have_found_a_user:
    'Chúng tôi đã tìm thấy một người dùng phù hợp với thông tin của bạn trên nền tảng.',
  please_enter_correct_email: 'Vui lòng nhập đúng email ',
  search_artist: 'Tìm kiếm nghệ sĩ',
  no_matching_result: 'Không có kết quả phù hợp',
  send_request: 'Gửi yêu cầu',
  no_account_exist_on_platform:
    'Không có tài khoản nào tồn tại trên nền tảng này. Bạn có muốn chúng tôi gửi yêu cầu tạo tài khoản tới email <strong>{{email}}</strong>',
  if_not_click_cancel: 'Nếu không, vui lòng nhấp vào "Hủy" và thử tìm kiếm lại.',
  price_low_to_high: 'Giá thấp đến cao',
  price_high_to_low: 'Giá cao đến thấp',
  date_old_to_new: 'Ngày cũ đến mớ',
  date_new_to_old: 'Ngày mới đến cũ',
  enter_email_to_search: 'Nhập email hoặc số điện thoại để tìm kiếm',
  medium_max_length: 'Chất liệu không được dài quá 100 ký tự',
  style_max_length: 'Phong cách không được dài quá 100 ký tự',
  subject_max_length: 'Chủ đề  không được dài quá 100 ký tự',
  please_search_again_for_the_artist: 'Vui lòng tìm kiếm lại nghệ sĩ',
  search_owner: 'Tìm kiếm chủ sở hữu',
  account_not_activated_yet: 'Tài khoản chưa được kích hoạt',
  we_will_send_your_request:
    'Chúng tôi sẽ gửi yêu cầu của bạn tới email này <strong>{{email}}</strong> ? Vui lòng kiểm tra địa chỉ email trước khi tiếp tục bước tiếp theo!',
  we_will_send_your_request_to_this_email:
    'Chúng tôi sẽ gửi yêu cầu của bạn tới <strong>{{email}}</strong> này, vui lòng đợi phản hồi của họ.',
  in_he_meantime_please_fill:
    'Trong lúc chờ đợi, vui lòng điền thủ công tên chủ sở hữu vào ô bên dưới.',
  please_enter_name: 'Vui lòng nhập tên',
  search_again: 'Tìm kiếm lại',
  want_to_change_name_search_again: 'Muốn đổi tên? <strong>{{search}}</strong>.',
  matching: 'Xác nhận tên tác phẩm và phiên bản',
  text_approve_certificate_request:
    'Để phê duyệt yêu cầu chứng nhận bạn cần phải so khớp thông tin trong yêu cầu với tác phẩm và số phiên bản chính xác của bạn.',
  artwork: 'Tác phẩm',
  please_select_name_artist: 'Hãy chọn tên nghệ sĩ',
  search: 'Tìm kiếm',
  we_will_send_your_request_email_check_before:
    'Chúng tôi sẽ gửi yêu cầu của bạn tới email này <strong>{{email}}</strong> ? Vui lòng kiểm tra địa chỉ email trước khi tiếp tục bước tiếp theo!',
  owner_searching: 'Tìm kiếm chủ sở hữu',
  current_edition_volume: 'Tổng số ấn bản hiện tại',
  status_and_location: 'Trạng thái & Vị trí',
  new_artwork: 'Tác phẩm mới',
  this_edition_has_created: 'Phiên bản này đã tạo chứng chỉ/hợp đồng.',
  the_vietnam_leading_online_art_platform: 'Nền tảng nghệ thuật trực tuyến hàng đầu Việt Nam',
  gladius_art_is_more_than_an_online_platform:
    'Gladius Art không chỉ là một nền tảng trực tuyến; đó là một hệ sinh thái năng động được thiết kế để trao quyền cho các nghệ sĩ, kết nối các nhà sưu tập và làm phong phú thêm các phòng trưng bày nghệ thuật theo cách chưa từng có.',
  our_mission: 'Sứ mệnh của chúng tôi',
  our_services: 'Dịch vụ của chúng tôi',
  main_features: 'Các tính năng chính',
  what_is_gladius_art: 'Nghệ thuật Gladius là gì',
  empowering_artists: 'Trao quyền cho nghệ sĩ',
  at_gladius_art:
    'Tại Gladius Art, chúng tôi tận tâm phá bỏ các rào cản trong thế giới nghệ thuật. Nền tảng của chúng tôi được thiết kế để giúp mọi người có thể tiếp cận nghệ thuật, bất kể vị trí hay những ràng buộc truyền thống. Được thiết kế đặc biệt để đáp ứng nhu cầu giải quyết những thách thức mà họ gặp phải trong một môi trường thị trường nghệ thuật thường khó tiếp cận.',
  breaking_traditional_constraints: 'Phá vỡ những ràng buộc truyền thống',
  by_providing_digital:
    'Bằng cách cung cấp không gian kỹ thuật số để các nghệ sĩ thể hiện tài năng của mình với khán giả toàn cầu, chúng tôi đang dân chủ hóa nghệ thuật và thúc đẩy kết nối giữa những người sáng tạo và những người đam mê trên toàn thế giới. Hãy tham gia cùng chúng tôi khi chúng tôi xác định lại bối cảnh nghệ thuật, tôn vinh sự đa dạng văn hóa và trao quyền cho các nghệ sĩ chia sẻ quan điểm độc đáo của họ với thế giới.',
  gladius_art_provides_innovative:
    'Gladius Art cung cấp các dịch vụ sáng tạo cho các nghệ sĩ, nhà sưu tập và phòng trưng bày nghệ thuật, thúc đẩy một cộng đồng năng động và hòa nhập trong lĩnh vực nghệ thuật toàn cầu.',
  collectors: 'Người sưu tầm',
  art_galleries: 'Phòng trưng bày nghệ thuật',
  gladius_art_offers_range:
    'Gladius Art cung cấp nhiều công cụ và dịch vụ để nâng cao kỹ năng của bạn và mở rộng phạm vi tiếp cận của bạn. Từ Thư viện Trực tuyến đến tính năng Tìm kiếm và Tải lên trực quan của chúng tôi, việc trưng bày tác phẩm nghệ thuật của bạn chưa bao giờ dễ dàng hơn thế. Tham gia nền tảng của chúng tôi để kết nối với những người sáng tạo đồng nghiệp và tương tác với khán giả toàn cầu gồm những người đam mê nghệ thuật.',
  discover_acquire_unique:
    'Khám phá và mua những tác phẩm nghệ thuật độc đáo từ các nghệ sĩ tài năng trên toàn thế giới với Gladius Art. Lựa chọn được tuyển chọn và hồ sơ cá nhân hóa của chúng tôi giúp bạn dễ dàng tìm thấy kiệt tác tiếp theo. Tham gia nền tảng của chúng tôi để khám phá và tương tác với nhiều cách thể hiện nghệ thuật đa dạng.',
  gladius_art_provides_dynamic_platform:
    'Gladius Art cung cấp một nền tảng năng động cho các phòng trưng bày để giới thiệu bộ sưu tập của họ tới khán giả toàn cầu. Hợp lý hóa việc quản lý và giao dịch hàng tồn kho bằng các công cụ phụ trợ của chúng tôi và mang lại sự chắc chắn hơn cho người mua của bạn bằng cách cấp chứng chỉ được ủy quyền trong nền tảng. Hãy tham gia cùng chúng tôi để đơn giản hóa quy trình của bạn đồng thời kết nối với các nghệ sĩ và những người đam mê nghệ thuật trên toàn thế giới.',
  join_us_at_gladius_art:
    'Hãy tham gia cùng chúng tôi tại Gladius Art và trở thành một phần của cộng đồng sôi động, nơi sự sáng tạo không có giới hạn. Cho dù bạn là nghệ sĩ, nhà sưu tập hay phòng trưng bày nghệ thuật, chúng tôi mời bạn khám phá, kết nối và phát triển trong thế giới nghệ thuật không ngừng phát triển .',
  join_us_now: 'Tham gia với chúng tôi ngay bây giờ',
  discover_the_core_functionalities: 'Khám phá các chức năng cốt lõi của Gladius Art',
  online_gallery_your_artworks: 'Thư viện trực tuyến cho tác phẩm nghệ thuật của bạn',
  showcase_your_createtion:
    'Trưng bày tác phẩm sáng tạo của bạn với khán giả trên toàn thế giới thông qua không gian thư viện ảo của chúng tôi.',
  start_exploring: 'Bắt ​​đầu khám phá',
  search_and_upload_artworks: 'Tìm kiếm và tải lên tác phẩm nghệ thuật',
  easy_upload_and_tag:
    'Dễ dàng tải lên và gắn thẻ tác phẩm nghệ thuật của bạn để tăng khả năng khám phá.',
  create_your_art_certificate: 'Tạo Chứng chỉ Nghệ thuật của bạn',
  use_our_in_platform_certificate:
    'Sử dụng hệ thống quản lý và cấp chứng chỉ trong nền tảng của chúng tôi để đảm bảo với khách hàng về tính xác thực và giá trị của tác phẩm nghệ thuật của bạn.',
  manage_your_artworks_and_editions: 'Quản lý tác phẩm nghệ thuật và ấn bản của bạn',
  streamline_inventory_management:
    'Hợp lý hóa việc quản lý và giao dịch hàng tồn kho bằng các công cụ phụ trợ trực quan của chúng tôi.',
  created_year: 'Năm đã tạo',
  contact_us: 'Liên hệ với chúng tôi',
  for_more_information:
    'Để biết thêm thông tin hoặc bất kỳ hỗ trợ nào, vui lòng liên hệ với chúng tôi.',
  phone: 'Điện thoại',
  year_of_creation: 'Năm sáng tạo',
  medium_material: 'Chất liệu',
  measurement_w_h: 'Số đo WxH(xD)',
  ask_for_price: 'Hỏi giá',
  about_artwork: 'Giới thiệu về tác phẩm',
  other_created_year: 'Các tác phẩm khác cùng năm được tạo',
  in_transaction_process: 'Trong quá trình giao dịch.',
  platform_version: 'Phiên bản nền tảng',
  date: 'Ngày',
  text_certificate_property_rights:
    'GladiusArt là đơn vị duy nhất cung cấp dịch vụ đăng ký quyền sở hữu liên quan đến tác phẩm nghệ thuật này. Chỉ cá nhân hoặc tổ chức hiện đã đăng ký với GladiusArt với tư cách là chủ sở hữu mới được phép chuyển tác phẩm nghệ thuật này sang quyền sở hữu mới. Vui lòng liên hệ GladiusArt để được hỗ trợ.',
  text_certificate_address:
    'Tầng 12B, Tòa nhà Cienco4, 180 Nguyễn Thị Minh Khai, Phường Võ Thị Sáu, Quận 3, Thành phố Hồ Chí Minh, Việt Nam',
  all_rights_reserved: 'Đã đăng ký Bản quyền',
  select_role: 'Chọn vai trò',
  gallery_owner: 'Chủ phòng trưng bày',
  online_certificate: 'Chứng chỉ trực tuyến',
  hide_certificate_only_show_for_me: 'Ẩn chứng chỉ, chỉ hiển thị cho tôi',
  public_certificate_for_eveyone: 'Chứng chỉ công khai cho mọi người',
  control_how_artwork_is_viewed: 'Kiểm soát cách xem tác phẩm nghệ thuật này',
  public: 'Công khai',
  artwork_details_page_visible_everyone:
    'Trang chi tiết tác phẩm nghệ thuật được hiển thị cho mọi người.',
  only_you_can_see_this_artwork:
    'Chỉ bạn mới có thể xem tác phẩm nghệ thuật này trong Quản lý bộ nhớ của mình.',
  the_artist_is_unknown: 'Nghệ sĩ không rõ',
  visibility_settings: 'Cài đặt hiển thị',
  text_setting_artist_profile_visible:
    'Để hiển thị Hồ Sơ Nghệ Sĩ của bạn, chế độ hiển thị hiện đã được đặt thành <strong>{{value}}</strong>.',
  exhibitions: 'Triển lãm',
  text_setting_exhibitions_visible:
    'Để hiển thị số lượng triển lãm trong Phòng Trưng Bày của bạn, chế độ hiển thị hiện đã được đặt thành <strong>{{value}}</strong>.',
  text_setting_artworks_visible:
    'Để hiển thị số lượng tác phẩm trong Phòng Trưng Bày của bạn, chế độ hiển thị hiện đã được đặt thành <strong>{{value}}</strong>.',
  text_setting_follower_visible:
    'Để hiển thị số lượng người theo dõi trong Phòng Trưng Bày của bạn, chế độ hiển thị hiện đã được đặt thành <strong>{{value}}</strong>.',
  text_setting_social_media_visible:
    'Để hiển thị các liên kết mạng xã hội trong Phòng Trưng Bày của bạn, chế độ hiển thị hiện đã được đặt thành <strong>{{value}}</strong>.',
  text_setting_persional_information_visible:
    'Để hiển thị thông tin cá nhân của bạn trong Phòng Trưng Bày, chế độ hiển thị hiện đã được đặt thành <strong>{{value}}</strong>.',
  social_media_links: 'Liên kết mạng xã hội',
  personal_information: 'Thông tin cá nhân',
  public_on_my_gallery: 'Công khai trong Phòng Trưng Bày của tôi',
  private_on_my_gallery: 'Riêng tư trong Phòng Trưng Bày của tôi',
  settings: 'Cài đặt',
  all_works: 'Tất cả các tác phẩm',
  only_show_for_me: 'Chỉ hiển thị cho tôi',
  cost_price: 'Giá thành',
  selling_price: 'Giá bán',
  legal_name: 'Tên pháp lý',
  nick_name: 'Biệt danh',
  please_verify_your_infomation_here: 'Vui lòng xác minh thông tin của bạn tại đây',
  id_account: 'ID Tài khoản',
  nick_name_account_name: 'Biệt danh (Tên tài khoản)',
  enter_your_email_social_link: 'Nhập email của bạn hoặc liên kết mạng xã hội...',
  your_contact_information: 'Thông tin liên hệ của bạn',
  please_enter_your_contact: 'Vui lòng nhập liên hệ của bạn',
  hide_selling_price: 'Ẩn giá bán, chỉ hiển thị cho tôi',
  we_cannot_find_the_artist_with_your_provided:
    'Chúng tôi không thể tìm thấy nghệ sĩ với thông tin bạn cung cấp trên nền tảng. Vui lòng thử tìm kiếm lại hoặc nhấp vào "Thêm thủ công" để điền tên nghệ sĩ theo cách thủ công.',
  add_manually: 'Thêm thủ công',
  select_orientation: 'Chọn hướng',
  artwork_images: 'Hình ảnh tác phẩm',
  please_enter_artist_name_manually: 'Vui lòng nhập tên nghệ sĩ theo cách thủ công',
  no_i_will_do_it_later: 'Không, tôi sẽ làm sau',
  we_cannot_find_an_account_that_match_your_mail:
    'Chúng tôi không thể tìm thấy tài khoản khớp với email/số điện thoại của bạn. Bạn chỉ có thể yêu cầu chứng chỉ cho tài khoản có sẵn trên nền tảng của chúng tôi.',
  do_you_want_to_try_search_again: 'Bạn có muốn thử tìm kiếm lại không?',
  edition_number_is_required: 'Phiên bản là bắt buộc',
  artwork_description: 'Mô tả tác phẩm nghệ thuật',
  tagged_request_details: 'Chi tiết yêu cầu được gắn thẻ',
  requestor: 'Người yêu cầu',
  text_are_you_sure_approve_tag_request:
    'Bạn có chắc chắn muốn phê duyệt yêu cầu được gắn thẻ từ <strong>{{name}<strong> không? Thông tin của bạn sẽ được hiển thị với tư cách là Nghệ sĩ của tác phẩm nghệ thuật này.',
  text_placeholder_reject_request:
    'Vui lòng cung cấp lý do tại sao bạn muốn từ chối yêu cầu này...',
  resend_success: 'Gửi lại thành công',
  tagged_request: 'Yêu cầu được gắn thẻ',
  active_artists: 'Tài khoản đã kích hoạt',
  add_artist_manually: 'Thêm nghệ sĩ theo cách thủ công',
  search_legal_name_nickname: 'Tìm kiếm theo tên pháp lý hoặc biệt hiệu...',
  add_artist_name: 'Thêm tên nghệ sĩ',
  enter_artist_name: 'Nhập tên nghệ sĩ',
  enter_artist_email_phone_number: 'Nhập email hoặc số điện thoại của nghệ sĩ nếu có',
  adding_the_artist_name_along:
    'Thêm tên nghệ sĩ cùng với thông tin liên hệ của họ có thể giúp chúng tôi phát hiện thời điểm họ hoạt động trên nền tảng và thông báo cho bạn.',
  please_enter_correct_email_phone_number: 'Vui lòng nhập đúng email hoặc số điện thoại',
  please_enter_artist_name: 'Vui lòng nhập tên nghệ sĩ',
  cancel_request_artist_tagging: 'Hủy yêu cầu gắn thẻ nghệ sĩ cho tác phẩm nghệ thuật đó',
  the_artist_has_approved_tagged_request: 'Nghệ sĩ đã chấp thuận yêu cầu được gắn thẻ của bạn.',
  active_users: 'Tài khoản đã kích hoạt',
  other_works_same_artist: 'Các tác phẩm khác của cùng một nghệ sĩ',
  new_artist_profile: 'Hồ sơ nghệ sĩ',
  receiver: 'Người nhận',
  request_canceled: 'Yêu cầu đã bị hủy',
  this_request_cancelled: 'Yêu cầu này đã bị hủy.',
  create_collection: 'Tạo bộ sưu tập',
  select_the_artworks_want_add_collection:
    'Chọn tác phẩm nghệ thuật bạn muốn thêm vào bộ sưu tập này',
  collection_detail: 'Chi tiết bộ sưu tập',
  collection_title: 'Tiêu đề bộ sưu tập',
  give_your_collection_title: 'Đặt tiêu đề cho bộ sưu tập của bạn',
  collection_visibility: 'Khả năng hiển thị bộ sưu tập',
  control_how_collection_is_viewed: 'Kiểm soát cách xem bộ sưu tập này',
  collection_visible_everyone: 'Bộ sưu tập được hiển thị cho mọi người.',
  only_you_see_this_collection:
    'Chỉ bạn mới có thể xem bộ sưu tập này trong Quản lý bộ nhớ của mình.',
  add_artworks_to_collection: 'Thêm tác phẩm nghệ thuật vào bộ sưu tập',
  select_artworks: 'Chọn tác phẩm nghệ thuật',
  collection_created_successfully: 'Bộ sưu tập được tạo thành công',
  selected: 'đã chọn',
  no_results_that_match_your_search: 'Không có kết quả nào phù hợp với tìm kiếm của bạn.',
  please_try_adjust_your_keywords:
    'Hãy cố gắng điều chỉnh từ khóa hoặc bộ lọc của bạn để có kết quả tốt hơn.',
  search_id_artwork_name: 'Tìm kiếm theo ID hoặc tên tác phẩm nghệ thuật...',
  edit_collection: 'Chỉnh sửa Bộ sưu tập',
  collection_edited_successfully: 'Bộ sưu tập được chỉnh sửa thành công',
  day_created: 'Ngày tạo',
  are_you_sure_you_want_to_reject:
    'Bạn có chắc chắn muốn từ chối yêu cầu được gắn thẻ từ <strong>[{{name}}]</strong>? Không thể hoàn tác hành động này.',
  the_artist_has_rejected_tagged_request: 'Nghệ sĩ đã từ chối yêu cầu được gắn thẻ của bạn.',
  add_artwork: 'Thêm tác phẩm',
  edit: 'Chỉnh sửa',
  collection: 'Bộ sưu tập',
  select_collection: 'Chọn bộ sưu tập',
  create_new_collection: 'Tạo bộ sưu tập mới',
  collection_name: 'Tên bộ sưu tập',
  please_input_collection_name: 'Vui lòng nhập tên bộ sưu tập',
  by_default_the_visibility_collection:
    'Theo mặc định, khả năng hiển thị của bộ sưu tập này sẽ được đặt là Riêng tư. Bạn luôn có thể thay đổi nó trong Quản lý bộ sưu tập.',
  other_collections_same_artist: 'Các bộ sưu tập khác của cùng một nghệ sĩ',
  you_can_attach_evidence_documents:
    'Bạn có thể đính kèm những tài liệu bằng chứng, hình ảnh chứng minh để thuyết phục nghệ sĩ, các định dạng cho phép : PDF, PNG, JPG, tối đa 5 file.',
  edition_number_exceeds_total: 'The edition number is greater than total edition.',
  text_upload_artwork_status_public: 'Hiển thị tại Phòng Trưng Bày, Tác Phẩm và Thư viện lưu trữ',
  text_upload_artwork_status_private: 'Hiển thị Phòng Trưng Bày và Thư viện lưu trữ',
  of_original_artwork: 'Tác Phẩm Nguyên Bản',
  height_abbreviation: 'C',
  width_abbreviation: 'R',
  deep_abbreviation: 'S',
  enter_the_legal_name_or_nick_name: 'Nhập tên pháp lý hoặc nghệ danh để tìm',
  file_size_exceeded: 'Tệp PDF vượt quá giới hạn 200MB.',
  now: 'Hiện tại',
  no_collection_available: 'Không có bộ sưu tập nào',
  all: 'Tất cả',
  sort: 'Sắp xếp',
  sold_and_donated_gifted: 'Đã bán, quyên góp/tặng',
  create_contract: 'Tạo hợp đồng',
  delete_edition: 'Delete edition',
  are_you_sure_want_delete_edition: 'Bạn có chắc chắn muốn xóa ấn bản này không?',
  certificate_number: 'Mã chứng nhận',
  search_subject: 'Tìm kiếm chủ đề...',
  create_share_link: 'Tạo liên kết chia sẻ',
  search_collection: 'Tìm kiếm bộ sưu tập',
  enter_year_of_birth: 'Nhập năm sinh',
  share_link_show: 'Chia sẻ liên kết hiển thị',
  email_phone_number: 'Email hoặc số điện thoại',
  this_artist_not_yet_available: 'Nghệ sĩ này hiện không có trên GladiusArt.',
  my_page: 'Trang của tôi',
  image_not_public: 'Hình ảnh không được công khai',
  delete_collection: 'Xóa bộ sưu tập',
  are_you_sure_to_delete_this_collection: 'Bạn có chắc chắn muốn xóa bộ sưu tập này không?',
  show_on_my_page_for_everyone: 'Hiển thị trên trang của tôi cho mọi người',
  only_show_my_page_to_me: 'Chỉ hiển thị trên trang của tôi với tôi',
  create_exhibition: 'Tạo triển lãm',
  exhibition_information: 'Thông tin triển lãm',
  please_fill_in_all_information: 'Vui lòng điền đầy đủ thông tin về triển lãm của bạn',
  cover: 'Ảnh bìa',
  we_recommend_you_to_portrait:
    'Chúng tôi khuyên bạn nên sử dụng ảnh chân dung có kích thước tệp dưới 10MB.',
  write_your_description_about_the_exhibition: 'Viết mô tả của bạn về triển lãm...',
  exhibition_detail: 'Chi tiết triển lãm',
  give_your_exhibition_title: 'Đặt tên cho triển lãm của bạn',
  exhibition_title: 'Tên triển lãm',
  date_start: 'Ngày bắt đầu',
  date_end: 'Ngày kết thúc',
  exhibition_organizer: 'Nhà tổ chức triển lãm',
  enter_name: 'Nhập tên',
  type_of_event: 'Loại sự kiện',
  free_ticket: 'Vé miễn phí',
  ticket_sale: 'Bán vé',
  private_event: 'Sự kiện riêng tư',
  chat: 'Trò chuyện',
  saved_as_draft_successfully: 'Đã lưu thành bản nháp thành công.',
  are_you_sure_to_delete_this_exhibition: 'Bạn có chắc chắn muốn xóa triển lãm này không?',
  on_going: 'ĐANG DIỄN RA',
  draft: 'BẢN NHÁP',
  edit_exhibition: 'Chỉnh sửa triển lãm',
  back_step: 'Quay lại',
};

export default viTranslation;
