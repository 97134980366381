import React from 'react';

import Text from 'commons/Text';
import Colors from 'configs/Colors';

import useStyles from './styles';

const TextInfo = ({ value, fontWeight, color, type }) => {
  const classes = useStyles();

  if (!value) {
    return null;
  }

  return (
    <Text
      className={classes.line}
      color={color || Colors.White}
      fontWeight={fontWeight}
      type={type || 'lg'}
    >
      {value}
    </Text>
  );
};

export default TextInfo;
