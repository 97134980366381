import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    aspectRatio: '310 / 206',
    width: '100%',
    backgroundColor: Colors.Gray3,
    '&:hover': {
      '& $dotHover': {
        opacity: 1,
        visibility: 'visible',
        transition: 'all 0.4s',
      },
    },
  },
  dotHover: {
    opacity: 0,
    visibility: 'hidden',
  },

  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  noneImage: {
    display: 'none',
  },
}));

export default useStyles;
