import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  gridWrap: {
    '&.MuiGrid-root': {
      paddingTop: 20,
    },
  },
}));

export default useStyles;
