import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import ExhibitionAction from '../ExhibitionAction';
import ExhibitionInfoRow from '../ExhibitionInfoRow';
import useStyles from './styles';

const ExhibitionInfo = ({ exhibition }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const dayStart = moment(exhibition?.date_start).format('DD/MM/YYYY');
  const dayEnd = moment(exhibition?.date_end).format('DD/MM/YYYY');

  return (
    <div>
      <Text>{t('exhibition')}</Text>
      <Text
        variant="h1"
        fontWeightBold
        className={classes.textTitleSize}
        mt={4}
      >
        {exhibition?.title}
      </Text>

      <Text
        mt={16}
        type="lg"
      >
        {dayStart} - {dayEnd}
      </Text>

      <ExhibitionInfoRow
        label={t('title')}
        content="Drop Hole"
        sx={{ mt: 5 }}
      />
      <ExhibitionInfoRow
        label={t('event_organizer')}
        content="-----"
        contentBold
        sx={{ mt: 2 }}
      />
      <ExhibitionInfoRow
        label={t('address')}
        content={exhibition?.address}
        contentBold
        sx={{ mt: 2 }}
      />
      <ExhibitionInfoRow
        label={t('artist')}
        content={exhibition?.owner?.name}
        sx={{ mt: 2 }}
      />

      <ExhibitionAction />
    </div>
  );
};

export default ExhibitionInfo;
