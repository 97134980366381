import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ArtworkCol from 'commons/ArtworkCol';
import {
  STATUS_CERTIFICATE_REQUEST_DISPLAY,
  STATUS_REQUEST_KEY,
  getLabelForOption,
} from 'configs/Constant';
import useRole from 'hooks/useRole';
import Routes from 'utils/Route';
import { formatDateString } from 'utils/utils';

import TableArtistTagRequestAction from '../TableArtistTagRequestAction';
import useStyles from './styles';

const getSizeData = (size) => {
  const { height, width, depth } = size || {};
  if (!height || !width) return '';
  return `${height} x ${width}${depth ? ` x ${depth}` : ''} cm`;
};

const TagRequestRow = ({ tagRequest, index, page, pageSize }) => {
  const language = useSelector((state) => state?.language?.currentLanguage);
  const { t } = useTranslation();
  const classes = useStyles();
  const { isCollectorOrGalleryOwner } = useRole();

  const { artwork, created_at } = tagRequest || {};
  const { size, total_edition, medium, year_created, period_created, images, title } =
    artwork || {};

  const sizeData = getSizeData(size);
  const isUniqueEdition = total_edition === 1;

  const displayIndex = (page - 1) * pageSize + (index + 1);

  const requestedAt = formatDateString(created_at);

  return (
    <TableRow>
      <TableCell>{displayIndex}</TableCell>
      <TableCell>
        <ArtworkCol
          id={tagRequest?.artwork?.id}
          image={images[0]}
          title={title}
          isLink
        />
      </TableCell>
      <TableCell>{getLabelForOption(medium, language)}</TableCell>
      <TableCell>{year_created || period_created}</TableCell>
      <TableCell>{sizeData}</TableCell>
      <TableCell>{isUniqueEdition ? t('unique') : total_edition}</TableCell>
      <TableCell>
        <Link to={Routes.GalleryDetail(tagRequest?.request_by?.uuid)}>
          {tagRequest?.request_by?.name}
        </Link>
      </TableCell>
      {isCollectorOrGalleryOwner && (
        <TableCell>
          <Link to={Routes.GalleryDetail(tagRequest?.request_to?.uuid)}>
            {tagRequest?.request_to?.legal_name}
          </Link>
        </TableCell>
      )}
      <TableCell>{requestedAt}</TableCell>
      <TableCell
        className={clsx({
          [classes.statusDenied]: tagRequest?.status === STATUS_REQUEST_KEY['REQUEST_DENIED'],
        })}
      >
        {t(STATUS_CERTIFICATE_REQUEST_DISPLAY[tagRequest?.status])}
      </TableCell>
      <TableCell>
        <TableArtistTagRequestAction tagRequest={tagRequest} />
      </TableCell>
    </TableRow>
  );
};

export default TagRequestRow;
