import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import API from 'configs/API';
import Routes from 'utils/Route';
import { axiosPatch } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

const updateExhibition = async (exhibitionId, formData) => {
  const { data } = await axiosPatch(API.ARTWORK.EXHIBITION_DETAIL(exhibitionId), formData);
  return data;
};

const useUpdateExhibition = (exhibitionId, userUuid) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => updateExhibition(exhibitionId, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API.ARTWORK.EXHIBITION_DETAIL(exhibitionId)],
      });
      ToastTopHelper.success(t('update_successfully'));
      const searchParams = createSearchParams({ tab: '5' }).toString();
      navigate({
        pathname: Routes.GalleryDetail(userUuid),
        search: searchParams,
      });
    },
  });
};

export default useUpdateExhibition;
