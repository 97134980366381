import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import API from 'configs/API';
import { STATUS_CHOICES } from 'configs/Constant';
import { createPersister } from 'react-query';

const useArtworksSoldDonated = (userUuid) => {
  const [searchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);

  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: userUuid,
    status__in: `${STATUS_CHOICES.SOLD},${STATUS_CHOICES.DONATED_GIFTED}`,
  };

  return useQuery({
    queryKey: [API.ARTWORK.ARTWORK, params],
    persister: createPersister(),
  });
};

export default useArtworksSoldDonated;
