import AddIcon from '@mui/icons-material/Add';
import { Box, FormLabel, Grid } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import BoxSection from 'commons/BoxSection';
import DialogSelectArtworks from 'commons/Collection/CollectionActionBlock/components/DialogSelectArtworks';
import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';
import TextArea from 'commons/TextArea';
import API from 'configs/API';
import { axiosGet } from 'utils/apis/axios';

import DeleteGroup from '../DeleteGroup';
import ExhibitionArtwork from '../ExhibitionArtwork';
import useStyles from './styles';

const ExhibitionGroupForm = ({
  fields,
  groupIndex,
  register,
  watch,
  onDeleteGroup,
  setValue,
  formState,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [selectedArtworks, setSelectedArtwork] = useState(
    watch(`groups.${groupIndex}.artwork_links`) || [],
  );

  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: user?.uuid,
  };

  const handleOpenDialogSelectArtwork = () => {
    setSearchParams({});
    setIsShowDialog(true);
  };

  const handleCancelDialog = () => {
    setSearchParams({});
    setIsShowDialog(false);
  };

  const fetchArtwork = async ({ pageParam }) => {
    const { data } = await axiosGet(false, API.ARTWORK.LIST_ARTWORK_SELECTION, {
      params: {
        ...params,
        page: pageParam,
      },
    });

    return data;
  };

  const { data, fetchNextPage } = useInfiniteQuery({
    queryKey: [API.ARTWORK.LIST_ARTWORK_SELECTION, params],
    queryFn: fetchArtwork,
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next) {
        return null;
      }
      const urlObj = new URL(lastPage?.next);
      const params = new URLSearchParams(urlObj.search);
      return params.get('page');
    },
    getPreviousPageParam: (firstPage) => {
      if (!firstPage?.previous) {
        return null;
      }
      const urlObj = new URL(firstPage?.previous);
      const params = new URLSearchParams(urlObj.search);
      return params.get('page');
    },
    enabled: isShowDialog,
  });

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 1) {
      fetchNextPage();
    }
  };

  const handleAddArtworks = () => {
    const artworkIds = selectedArtworks.map((item) => item?.id);
    setValue(`groups.${groupIndex}.artwork_links`, selectedArtworks, { shouldValidate: true });
    setValue(`groups.${groupIndex}.artworks`, artworkIds, { shouldValidate: true });
    setIsShowDialog(false);
  };

  const handleSelectArtwork = (artwork) => () => {
    if (selectedArtworks.some((item) => item.id === artwork.id)) {
      const artworks = selectedArtworks.filter((item) => item.id !== artwork.id);
      setSelectedArtwork(artworks);
    } else {
      setSelectedArtwork([...selectedArtworks, artwork]);
    }
  };

  const handleClickDeleteArtwork = (artwork) => () => {
    const artworks = selectedArtworks.filter((item) => item.id !== artwork.id);
    const artworkIds = artworks.map((item) => item?.id);

    setValue(`groups.${groupIndex}.artworks`, artworkIds, { shouldValidate: true });
    setValue(`groups.${groupIndex}.artwork_links`, artworks, { shouldValidate: true });
    setSelectedArtwork(artworks);
  };

  return (
    <>
      <BoxSection
        heading={watch(`groups.${groupIndex}.title`)}
        headingRight={fields?.length > 1 && <DeleteGroup onDelete={onDeleteGroup} />}
      >
        <FormInput
          {...register(`groups.${groupIndex}.title`)}
          value={watch(`groups.${groupIndex}.title`)}
          error={!!formState?.errors?.groups?.[groupIndex]?.title}
          helperText={formState?.errors?.groups?.[groupIndex]?.title?.message}
          isRequired
          label={t('title')}
          placeholder={t('enter_a_title')}
        />

        <Box sx={{ mt: 2 }}>
          <FormLabel>
            <Text
              variant="span"
              type="sm"
            >
              {t('description')}
            </Text>
          </FormLabel>

          <TextArea
            textMaxLength="2000"
            {...register(`groups.${groupIndex}.description`)}
            value={watch(`groups.${groupIndex}.description`)}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <FormLabel required>
            <Text
              variant="span"
              type="sm"
            >
              {t('artworks')}
            </Text>
          </FormLabel>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={3}
              xs={12}
            >
              <div
                className={classes.boxAddArtwork}
                onClick={handleOpenDialogSelectArtwork}
              >
                <AddIcon />
                <Text
                  type={'xs'}
                  className={classes.addArtworkText}
                >
                  {t('add')}
                </Text>
              </div>
            </Grid>

            {selectedArtworks?.map((artwork, index) => (
              <Grid
                item
                xs={12}
                lg={3}
                key={index}
              >
                <ExhibitionArtwork
                  artwork={artwork}
                  onRemoveArtwork={handleClickDeleteArtwork(artwork)}
                />
              </Grid>
            ))}
          </Grid>

          <FormLabel
            sx={{ mt: 1, display: 'inline-block' }}
            error
          >
            {formState?.errors?.groups?.[groupIndex]?.artworks?.message}
          </FormLabel>
        </Box>
      </BoxSection>

      <DialogSelectArtworks
        artworks={data}
        isShowDialog={isShowDialog}
        onScroll={handleScroll}
        onCancel={handleCancelDialog}
        onSubmit={handleAddArtworks}
        onClickSelectArtworkItem={handleSelectArtwork}
        selectedArtworks={selectedArtworks}
      />
    </>
  );
};

export default ExhibitionGroupForm;
