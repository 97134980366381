import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const ExhibitionAction = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      sx={{ mt: 8 }}
      spacing={2}
    >
      <Button
        variant="contained"
        className={classes.button}
      >
        {t('view_virtual_exhibition')}
      </Button>

      <Button variant="outlined">
        <FavoriteBorderIcon />
      </Button>

      <Button variant="outlined">
        <MoreHorizIcon />
      </Button>
    </Stack>
  );
};

export default ExhibitionAction;
