import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArtworkCol from 'commons/ArtworkCol';
import CertificateDialog from 'pages/ArtworkDetail/components/CertificateDialog';
import { formatDateString, getTextEdition } from 'utils/utils';

import CertificateLogMoDal from '../CertificateLogMoDal';
import TableCertificateAction from '../TableCertificateAction';
import useStyles from './styles';

const CertificateRow = ({ certificate, index, page, pageSize }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isShowCertificateView, setIsShowCertificateView] = useState(false);
  const [isShowCertificateLog, setIsShowCertificateLog] = useState(false);

  const displayIndex = (page - 1) * pageSize + (index + 1);
  const formattedIssuedDate = formatDateString(certificate?.created_at);
  const formattedUpdateDate = formatDateString(certificate?.updated_at);

  const handleCancelCertificateView = () => {
    setIsShowCertificateView(false);
  };

  const handleCancelCertificateLog = () => {
    setIsShowCertificateLog(false);
  };

  return (
    <>
      <TableRow
        key={index}
        className={clsx(classes.tableRowBorder)}
      >
        <TableCell>{displayIndex}</TableCell>

        <TableCell>
          <ArtworkCol
            id={certificate?.artwork?.id}
            image={certificate?.artwork?.image}
            title={certificate?.artwork?.title}
            isLink={false}
          />
        </TableCell>
        <TableCell>
          {getTextEdition(t, certificate?.edition_number, certificate?.artwork?.total_edition)}
        </TableCell>
        <TableCell>{certificate?.issued_by?.name}</TableCell>
        <TableCell>{certificate?.code}</TableCell>
        <TableCell>{formattedIssuedDate}</TableCell>
        <TableCell>{formattedUpdateDate}</TableCell>
        <TableCell>{certificate?.status}</TableCell>
        <TableCell>
          <TableCertificateAction
            setIsShowCertificateView={setIsShowCertificateView}
            setIsShowCertificateLog={setIsShowCertificateLog}
          />
        </TableCell>
      </TableRow>

      {certificate && (
        <CertificateDialog
          open={isShowCertificateView}
          onClose={handleCancelCertificateView}
          artwork={certificate?.artwork}
          certificate={certificate}
        />
      )}
      <CertificateLogMoDal
        certificate={certificate}
        isShowCertificateLog={isShowCertificateLog}
        onCancelCertificateLog={handleCancelCertificateLog}
      />
    </>
  );
};

export default CertificateRow;
