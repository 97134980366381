const Routes = {
  Home: '/',
  Login: '/login',
  MyAccount: '/my-account',
  GalleryDetail: (artistId = ':artistId') => `/page/${artistId}`,
  SignUp: '/sign-up',
  UploadArtwork: '/upload-artwork',
  EditArtwork: (id = ':id') => `/edit-artwork/${id}`,
  ArtworkDetail: (id = ':id') => `/artwork-detail/${id}`,
  ArtistProfile: (id = ':id') => `/artist-profile/${id}`,
  EditArtistProfile: '/edit-artist-profile',
  Artists: '/artists',
  RequestCertificateId: (artworkId = ':artworkId') => `/artwork/${artworkId}/request-certificate`,
  PageNotFound: '/page-not-found',
  ManageEdition: '/manage-edition',
  ManageCertificate: '/manage-certificate',
  ManageCollection: '/manage-collection',
  ChooseAccountType: '/choose-account-type',
  ReviewCertificateRequest: (id = ':id') => `/review-certificate-request/${id}`,
  ReviewTagRequest: (id = ':id') => `/review-tag-request/${id}`,
  EditCertificateRequest: (id = ':id') => `/edit-certificate-request/${id}`,
  CreateCertificate: (id = ':id') => `/artwork/${id}/create-certificate`,
  ManageArtwork: '/manage-artwork',
  AboutUs: '/about-us',
  VerifyInformation: '/verify-information',
  CreateCollection: '/create-collection',
  EditCollection: (id = ':id') => `/edit-collection/${id}`,
  CollectionDetail: (id = ':id') => `/collection/${id}`,
  ViewCertificate: (id = ':id') => `/view-certificate/${id}`,
  Chat: '/chat',
  UserArtistArtwork: (id = ':id', artistId = ':artistId') =>
    `/user/${id}/artist/${artistId}/artworks`,
  CreateExhibition: '/create-exhibition',
  EditExhibition: (id = ':id') => `/edit-exhibition/${id}`,
  ExhibitionDetail: (id = ':id') => `/exhibition-detail/${id}`,
};

export default Routes;
