import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    '&.MuiButton-root': {
      padding: '12px 16px',
    },
  },
}));

export default useStyles;
