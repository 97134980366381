import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  boxAddArtwork: {
    border: `2px dashed ${Colors.Gray5}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 204,
    textAlign: 'center',
    backgroundColor: Colors.White,
    cursor: 'pointer',
  },
}));

export default useStyles;
