import * as yup from 'yup';

export const collectionSchema = yup.object().shape({
  title: yup
    .string()
    .required('Title is required')
    .max(50, 'Title must be no more than 50 characters long'),
  description: yup
    .string()
    .notRequired()
    .max(300, 'Description must be no more than 300 characters long'),
  addedArtworks: yup.array(),
});
