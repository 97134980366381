import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Container, Stack, Tab, Tabs } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, Link, useParams, useSearchParams } from 'react-router-dom';

import ArtistPage from 'commons/ArtistPage';
import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import TabPanel from 'commons/TabPanel';
import Text from 'commons/Text';
import API from 'configs/API';
import { ReactComponent as PenEdit } from 'images/pen_edit.svg';
import { ReactComponent as ShareIcon } from 'images/share.svg';
import Routes from 'utils/Route';
import { isUUID } from 'utils/Validation';
import { axiosGet } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import ListArtwork from './components/ListArtwork';
import useUpdateArtist from './hooks/useUpdateArtist';
import useStyles from './styles';
import { editArtistSchema } from './validate';

const UserArtistArtwork = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.auth.account.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const { id, artistId, tokenParam } = useParams();
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const checkIsUUID = isUUID(artistId);

  const isOwner = user?.uuid === id;

  const { data: artist } = useQuery({
    queryKey: [API.ARTWORK.USER_ARITST(artistId)],
  });

  const { mutate: updateArtist } = useUpdateArtist(artistId);

  const { register, handleSubmit, formState } = useForm({
    values: {
      artistName: artist?.artist_name,
      yearOfBirth: artist?.year_of_birth,
      yearOfDeath: artist?.year_of_death,
    },
    mode: 'onChange',
    resolver: yupResolver(editArtistSchema),
  });

  const params = useMemo(() => {
    const queryParams = {
      owner_uuid: id,
      token: token || tokenParam,
    };
    queryParams[checkIsUUID ? 'artist_uuid' : 'artist_artwork_id'] = artistId;
    return queryParams;
  }, [artistId, checkIsUUID, id, token, tokenParam]);

  const { data: artworks } = useQuery({
    queryKey: [API.ARTWORK.LIST_ARTWORK_BY_ARTIST, params],
  });

  const tabValue = Number(searchParams.get('tab') || 1);

  const handleTabChange = (e, newValue) => {
    searchParams.set('tab', newValue);
    setSearchParams(searchParams, { replace: true });
  };

  const getLabel = ({ text, number }) => {
    return `${text} ${number}`;
  };

  const handleClosePopUpEdit = () => {
    setIsShowDialog(false);
  };

  const handleSubmitDialog = (data) => {
    const formData = {
      artist_name: data?.artistName,
      year_of_birth: data?.yearOfBirth,
      year_of_death: data?.yearOfDeath,
    };

    updateArtist(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [API.ARTWORK.USER_ARITST(artistId)],
        });
        handleClosePopUpEdit();
        ToastTopHelper.success(t('update_successfully'));
      },
    });
  };

  const handleEditArtist = () => {
    setIsShowDialog(true);
  };

  const handleCreateLink = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.CREATE_SINGLE_ARTIST_TOKEN, {
      params: { user_uuid: user?.uuid },
    });

    if (success) {
      const currentUrl = `${window.location.origin}${window.location.pathname}`;
      const shareLink = `${currentUrl}?token=${data}`;

      navigator.clipboard.writeText(shareLink).then(() => {
        ToastTopHelper.success(t('link_has_been_copied'));
      });
    }
  };

  useEffect(() => {
    if (!checkIsUUID) {
      return;
    }

    const getProfile = async () => {
      const { success, data } = await axiosGet(API.AUTH.PROFILE_INFO, {
        params: { user_uuid: artistId },
      });
      if (success) {
        setUserProfile(data);
      }
    };

    getProfile();
  }, [artistId, checkIsUUID]);

  return (
    <Container>
      <Link
        to={{
          pathname: Routes.GalleryDetail(id),
          search: createSearchParams({ tab: 2 }).toString(),
        }}
      >
        <Stack
          direction={'row'}
          sx={{ mt: '42px', alignItems: 'center' }}
        >
          <ArrowBackIcon />
          <Text
            ml={8}
            type="sm"
          >
            {t('back_to_collector_page')}
          </Text>
        </Stack>
      </Link>

      <div className={classes.row}>
        <Text
          type="2xl"
          fontWeightBold
        >
          {artist?.name || artist?.artist_name}{' '}
          {artist?.year_of_birth ? `(B.${artist.year_of_birth})` : '(N/A)'}
        </Text>

        <Stack
          direction="row"
          spacing={2}
        >
          {!checkIsUUID && isOwner && (
            <Button
              variant="outlined"
              onClick={handleEditArtist}
            >
              <PenEdit />
              <Text
                variant="span"
                ml={8}
              >
                {t('edit')}
              </Text>
            </Button>
          )}

          {isOwner && (
            <Button
              onClick={handleCreateLink}
              variant="outlined"
            >
              <ShareIcon />
              <Text
                variant="span"
                ml={8}
              >
                {t('share')}
              </Text>
            </Button>
          )}
        </Stack>
      </div>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        <Tab
          label={getLabel({
            text: t('artworks'),
            number: artworks?.length || '',
          })}
          value={1}
          className={classes.tab}
        />

        <Tab
          label={t('artist_profile')}
          value={2}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <ListArtwork artworks={artworks} />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={2}
      >
        {checkIsUUID && (
          <ArtistPage
            userProfile={userProfile}
            artistUuid={artistId}
            wrapStyle={classes.wrapArtistProfileContent}
          />
        )}
        {!checkIsUUID && (
          <Text className={classes.textArtistNA}>{t('this_artist_not_yet_available')}</Text>
        )}
      </TabPanel>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={'Edit Artist Page'}
        onCancel={handleClosePopUpEdit}
        textCancel={t('cancel')}
        textSubmit={t('save')}
        onSubmit={handleSubmit(handleSubmitDialog)}
        submitButtonStyle={classes.buttonDialog}
        cancelButtonStyle={classes.buttonDialog}
      >
        <FormInput
          {...register('artistName')}
          label={t('artist_name')}
          isRequired
          error={!!formState?.errors?.artistName}
          helperText={formState?.errors?.artistName?.message}
          wrapFormInputStyle={classes.formControl}
        />

        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <FormInput
            {...register('yearOfBirth')}
            label={t('year_of_birth')}
            error={!!formState?.errors?.yearOfBirth}
            helperText={formState?.errors?.yearOfBirth?.message}
            wrapFormInputStyle={classes.formInputWrap}
          />

          <FormInput
            {...register('yearOfDeath')}
            label={t('year_of_death')}
            error={!!formState?.errors?.yearOfDeath}
            helperText={formState?.errors?.yearOfDeath?.message}
            wrapFormInputStyle={classes.formInputWrap}
          />
        </Stack>
      </DialogCustom>
    </Container>
  );
};

export default UserArtistArtwork;
