import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArtworkCol from 'commons/ArtworkCol';
import Text from 'commons/Text';
import { getLabelForOption, STATUS_REQUEST_KEY } from 'configs/Constant';
import Routes from 'utils/Route';
import { formatCurrency } from 'utils/utils';

import DisplayData from '../../../Common/DisplayData';
import TableArtworkCollectorAction from '../../../Common/TableArtworkCollectorAction';
import useStyles from './styles';

const ArtworkRow = ({ artwork, index, page, pageSize }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = useSelector((state) => state?.language?.currentLanguage);
  const navigate = useNavigate();
  const { artist_tag_request, artist_artwork } = artwork || {};

  const { status, request_to } = artist_tag_request || {};

  const displayIndex = (page - 1) * pageSize + (index + 1);

  const priceFormat = formatCurrency(artwork?.price);

  const getTextArtistName = () => {
    if (!artist_artwork?.artist_name && !request_to?.id) {
      return 'N/A';
    }

    if (request_to?.id) {
      return request_to?.name;
    }

    return artist_artwork?.artist_name;
  };

  const getSizeData = (size) => {
    const { height, width, depth } = size || {};

    if (!height || !width) return null;

    return `${height} x ${width}${depth ? ` x ${depth}` : ''} cm`;
  };

  const sizeData = getSizeData(artwork?.size);

  const handleNavigateToGallery = () => {
    if (status !== STATUS_REQUEST_KEY.REQUEST_APPROVED) {
      return;
    }

    navigate(Routes.GalleryDetail(request_to?.uuid));
  };

  const handleNavigateArtworkDetail = () => {
    navigate(Routes.ArtworkDetail(artwork?.id));
  };

  return (
    <TableRow>
      <TableCell>{displayIndex}</TableCell>
      <TableCell onClick={handleNavigateArtworkDetail}>
        <ArtworkCol
          id={artwork?.id}
          image={artwork?.images[0]}
          title={artwork?.title}
          isLink
        />
      </TableCell>
      <DisplayData value={artwork?.is_public ? t('public') : t('private')} />
      <TableCell>
        <Text
          type="sm"
          clickAble={artwork?.artist?.name}
          onClick={handleNavigateToGallery}
          className={clsx(classes.textArtist, {
            [classes.textArtistStatusApprove]: status === STATUS_REQUEST_KEY.REQUEST_APPROVED,
          })}
        >
          {getTextArtistName()}
          {status && status === STATUS_REQUEST_KEY.REQUEST_APPROVED && (
            <CheckIcon className={classes.checkIcon} />
          )}
          {status &&
            (status === STATUS_REQUEST_KEY.REQUEST_DENIED ||
              status === STATUS_REQUEST_KEY.REQUEST_CANCELED) && (
              <CloseIcon className={classes.closeIcon} />
            )}
        </Text>
      </TableCell>
      <DisplayData value={getLabelForOption(artwork?.medium, language)} />
      <DisplayData value={artwork?.year_created || artwork?.period_created} />
      <DisplayData value={sizeData} />
      <DisplayData value={priceFormat ? `${priceFormat} ${artwork?.currency}` : null} />
      <DisplayData />
      <DisplayData value={artwork?.location?.location} />
      <DisplayData value={artwork?.status?.value} />
      <TableArtworkCollectorAction artwork={artwork} />
    </TableRow>
  );
};

export default ArtworkRow;
