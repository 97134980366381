import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tabs: {
    '&.MuiTabs-root': {
      borderBottom: `1px solid ${Colors.Grey3}`,
    },
  },
}));

export default useStyles;
