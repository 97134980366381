import { Box } from '@mui/material';
import React from 'react';

import Image from 'commons/Image';
import GridLayout from 'layouts/GridLayout';

import ExhibitionInfo from '../ExhibitionInfo';
import useStyles from './styles';

const ExhibitionWrapper = ({ exhibition }) => {
  const classes = useStyles();

  return (
    <Box sx={{ pt: 5 }}>
      <GridLayout
        leftColumnProps={{
          lg: 4,
        }}
        rightColumnProps={{
          lg: 8,
        }}
        spacing={10}
      >
        <div>
          <Image
            src={exhibition?.cover_image}
            imageStyle={classes.image}
          />
        </div>
        <ExhibitionInfo exhibition={exhibition} />
      </GridLayout>
    </Box>
  );
};

export default ExhibitionWrapper;
