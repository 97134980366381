import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  category: {
    marginTop: 8,
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
    '&:first-child': {
      marginTop: 16,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  icon: {
    marginRight: 12,
    marginTop: 7,
    width: 10,
    height: 10,
    backgroundColor: Colors.Black,
    borderRadius: 2,
  },

  details: {
    paddingBottom: 8,
  },
}));

export default useStyles;
