import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BoxSection from 'commons/BoxSection';
import Text from 'commons/Text';

import ExhibitionGroupActionItem from '../ExhibitionGroupActionItem';
import useStyles from './styles';

const CreateGroupAction = ({ watch, onAddGroup, onSelectGroup }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <BoxSection heading={t('exhibition_groupings')}>
      <div>
        {watch('groups')?.map((group, index) => (
          <ExhibitionGroupActionItem
            key={index}
            onSelectGroup={onSelectGroup}
            index={index}
            {...group}
          />
        ))}
      </div>

      <Button
        variant="outlined"
        fullWidth
        className={classes.buttonAddGroup}
        onClick={onAddGroup}
      >
        <AddIcon />
        <Text ml={8}> {t('add_new_group_of_artworks')}</Text>
      </Button>
    </BoxSection>
  );
};

export default CreateGroupAction;
