import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { ReactComponent as TrashIcon } from 'images/trash_red.svg';

const DeleteGroup = ({ onDelete }) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      onClick={onDelete}
      sx={{ cursor: 'pointer' }}
    >
      <TrashIcon />
      <Text
        type="sm"
        color={Colors.Red}
        clickAble
        fontWeightMedium
      >
        {t('delete_group')}
      </Text>
    </Stack>
  );
};

export default DeleteGroup;
