import React from 'react';
import { useParams } from 'react-router-dom';

import useExhibition from 'react-query/hooks/queries/useExhibition';

import WrapStepperEditExhibition from './components/WrapStepperEditExhibition';
import useStyles from './styles';

const EditExhibition = () => {
  const classes = useStyles();
  const { id: exhibitionId } = useParams();

  const { exhibition, isSuccess } = useExhibition(exhibitionId);

  return (
    <div className={classes.wrapper}>
      {isSuccess && <WrapStepperEditExhibition exhibition={exhibition} />}
    </div>
  );
};

export default EditExhibition;
