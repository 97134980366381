import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Stepper from 'commons/Stepper';
import { createExhibitionSchema } from 'utils/Validation';

import ExhibitionGroupStep from './components/ExhibitionGroupStep';
import ExhibitionStepOne from './components/ExhibitionStepOne';
import useStyles from './styles';

const CreateExhibition = () => {
  const classes = useStyles();
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep((prev) => prev + 1);
  };

  const exhibitionFormState = useForm({
    defaultValues: {
      coverUploadFile: {},
      isPublic: false,
      isDraft: true,
    },
    mode: 'onChange',
    resolver: yupResolver(createExhibitionSchema),
  });

  return (
    <div className={classes.wrapper}>
      <Container>
        <Stepper active={step === 1}>
          <ExhibitionStepOne
            onNextStep={handleNextStep}
            exhibitionFormState={exhibitionFormState}
            step={step}
          />
        </Stepper>

        <Stepper active={step === 2}>
          <ExhibitionGroupStep
            step={step}
            setStep={setStep}
            informationFormState={exhibitionFormState}
          />
        </Stepper>
      </Container>
    </div>
  );
};

export default CreateExhibition;
