import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';

import API from 'configs/API';

import ExhibitionTabs from './components/ExhibitionTabs';
import ExhibitionWrapper from './components/ExhibitionWrapper';

const ExhibitionDetail = () => {
  const { id } = useParams();

  const { data: exhibition, isPending } = useQuery({
    queryKey: [API.ARTWORK.EXHIBITION_DETAIL(id)],
  });

  if (isPending) {
    return;
  }

  return (
    <Container>
      <ExhibitionWrapper exhibition={exhibition} />
      <ExhibitionTabs exhibition={exhibition} />
    </Container>
  );
};

export default ExhibitionDetail;
