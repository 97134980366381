import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

const saveAsDraftExhibition = async (formData) => {
  const { data } = await axiosPost(false, API.ARTWORK.SAVE_DRAFT_EXHIBITION, formData);
  return data;
};

const useSaveAsDraft = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: saveAsDraftExhibition,
    onSuccess: () => {
      ToastTopHelper.success(t('create_successfully'));
    },
  });
};

export default useSaveAsDraft;
