import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import BoxSection from 'commons/BoxSection';
import DayMonthYearSelector from 'commons/DayMonthYearSelector';
import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import GroupThreeButtonProgress from 'commons/GroupThreeButtonProgress';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import useSaveAsDraft from 'pages/CreateExhibition/hooks/useSaveAsDraft';
import Routes from 'utils/Route';

import ExhibitionTypeChoice from '../ExhibitionTypeChoice';
import useStyles from './styles';

const ExhibitionDetailRight = ({ onNextStep, exhibitionFormState, step }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);
  const { uploadImage } = useUploadImageS3();

  const { mutate: saveAsDraftExhibition } = useSaveAsDraft();
  const { register, formState, watch, setValue, trigger, handleSubmit } = exhibitionFormState;

  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleSaveAsDraft = async (data) => {
    const { coverUploadFile } = watch();
    const formData = {
      title: data?.exhibitionTitle,
      date_start: moment(data.dateStart).format('YYYY-MM-DD'),
      date_end: moment(data.dateEnd).format('YYYY-MM-DD'),
      preface: data?.preface,
      address: data?.address,
      organizer_name: data?.organizerName,
      event_type: data?.typeOfEvent?.key,
    };

    if (
      coverUploadFile &&
      Object.keys(coverUploadFile).length > 0 &&
      (await uploadImage(coverUploadFile)).success
    ) {
      formData.cover_image = coverUploadFile.imageKey;
    }

    saveAsDraftExhibition(formData, {
      onSuccess: () => {
        navigate(Routes.GalleryDetail(user?.uuid));
      },
    });
  };

  const handleShowDialogCancel = () => {
    setIsShowDialog(true);
  };

  const handleCancleDialog = (e) => {
    e.preventDefault();
    setIsShowDialog(false);
  };
  const handleSubmitDialog = (e) => {
    e.preventDefault();
    const searchParams = createSearchParams({ tab: '5' }).toString();
    navigate({
      pathname: Routes.GalleryDetail(user?.uuid),
      search: searchParams,
    });
    setIsShowDialog(false);
  };

  return (
    <div>
      <BoxSection heading={t('exhibition_detail')}>
        <FormInput
          {...register('exhibitionTitle')}
          label={t('exhibition_title')}
          placeholder={t('give_your_exhibition_title')}
          isRequired
          wrapFormInputStyle={classes.formControl}
          labelStyle={classes.labelStyle}
          helperText={formState?.errors.exhibitionTitle?.message}
          error={!!formState?.errors.exhibitionTitle}
        />
        <div className={classes.wrapDateSelector}>
          <DayMonthYearSelector
            title={t('date_start')}
            selectedDate={watch('dateStart')}
            isRequired
            onChangeDate={(date) => {
              setValue('dateStart', date);
              trigger('dateStart');
            }}
            error={!!formState.errors.dateStart}
            helperText={formState.errors.dateStart?.message}
          />
          <DayMonthYearSelector
            title={t('date_end')}
            selectedDate={watch('dateEnd')}
            isRequired
            onChangeDate={(date) => {
              setValue('dateEnd', date);
              trigger('dateEnd');
            }}
            error={!!formState.errors.dateEnd}
            helperText={formState.errors.dateEnd?.message}
          />
        </div>
        <FormInput
          {...register('organizerName')}
          label={t('exhibition_organizer')}
          placeholder={t('enter_name')}
          wrapFormInputStyle={classes.formControl2}
          labelStyle={classes.labelStyle}
        />
        <FormInput
          {...register('address')}
          label={t('address')}
          placeholder={t('enter_your_address')}
          wrapFormInputStyle={classes.formControl2}
          labelStyle={classes.labelStyle}
          helperText={formState?.errors.address?.message}
          error={!!formState?.errors.address}
        />
        <ExhibitionTypeChoice
          control={exhibitionFormState.control}
          name="typeOfEvent"
          disabled={false}
          formState={exhibitionFormState.formState}
        />
      </BoxSection>
      <div className={classes.wrapGroupButton}>
        <GroupThreeButtonProgress
          step={step}
          textButtonOne={t('next')}
          textButtonTwo={t('cancel')}
          textButtonThree={t('save_as_draft')}
          onClickButtonOne={handleSubmit(onNextStep)}
          onClickButtonTwo={handleShowDialogCancel}
          onClickButtonThree={handleSubmit(handleSaveAsDraft)}
          linearProgressStyle={classes.linearProgressStyle}
        />
      </div>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('cancel')}
        textCancel={t('cancel')}
        textSubmit={t('ok')}
        onCancel={handleCancleDialog}
        onSubmit={handleSubmitDialog}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
        cancelButtonStyle={clsx(classes.buttonDialog)}
      >
        {t('are_you_sure_exit_certificate')}
      </DialogCustom>
    </div>
  );
};

export default ExhibitionDetailRight;
