import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

const TabIntroduction = ({ introduction }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 5 }}>
      <Text
        fontWeightMedium
        type="lg"
      >
        {t('preface')}
      </Text>

      <Text mt={16}>{introduction}</Text>
    </Box>
  );
};

export default TabIntroduction;
