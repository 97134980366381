import { Button, SpeedDialIcon } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TESTID } from 'configs/Constant';
import useRole from 'hooks/useRole';
import { ReactComponent as FolderAddLine } from 'images/folder_add_line.svg';
import { ReactComponent as ImageArtworkUpload } from 'images/image_add_line.svg';
import Routes from 'utils/Route';

import useStyles from './styles';

const SpeedDialNavigation = () => {
  const [openSpeedDial, setOpenSpeedDial] = useState(true);
  const { isArtist } = useRole();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial((prevState) => !prevState);
  };

  const handleNavigateUploadArtwork = () => {
    navigate(Routes.UploadArtwork);
  };

  const handleNavigateCreationCollection = () => {
    navigate(Routes.CreateCollection);
  };

  const handleNavigateCreationExhibition = () => {
    navigate(Routes.CreateExhibition);
  };

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={handleOpenSpeedDial}
      testid={TESTID.SPEDDIAL_COLLECTION_BUTTON}
    >
      <SpeedDialIcon
        className={clsx(classes.speedDialIcon, {
          [classes.speedDialIconOpen]: openSpeedDial,
        })}
      />

      <div
        className={clsx(classes.wrapItemSpeedDial, {
          [classes.openSpeedDial]: openSpeedDial,
        })}
        testid={TESTID.SPEDDIAL_COLLECTION_CONTENT}
      >
        {isArtist && (
          <div
            onClick={handleNavigateCreationExhibition}
            className={clsx(classes.itemSpeedDial)}
          >
            <FolderAddLine />
            <div>{t('create_exhibition')}</div>
          </div>
        )}
        <div
          onClick={handleNavigateCreationCollection}
          className={clsx(classes.itemSpeedDial)}
        >
          <FolderAddLine />
          <div>{t('create_collection')}</div>
        </div>
        <div
          onClick={handleNavigateUploadArtwork}
          testid={TESTID.TEXT_ARTWORK_UPLOAD}
          className={clsx(classes.itemSpeedDial, classes.itemSpeedDialFirst)}
        >
          <ImageArtworkUpload />
          <div>{t('artwork_upload')}</div>
        </div>
      </div>
    </Button>
  );
};

export default SpeedDialNavigation;
