import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  autocompleteMarginTopAdjustment: {
    '&.MuiAutocomplete-root': {
      marginTop: -8,
    },
    '& .MuiInputBase-root': {
      padding: '5px !important',
    },
  },
}));

export default useStyles;
