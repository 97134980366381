import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BoxSection from 'commons/BoxSection';
import Text from 'commons/Text';
import TextArea from 'commons/TextArea';
import UploadImage from 'commons/UploadImage';
import useImageCompression from 'hooks/useImageCompression';
import useUploadImageS3 from 'hooks/useUploadImageS3';

import useStyles from './styles';

const ExhibitionDetailLeft = ({ exhibitionFormState }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, formState, setValue, watch } = exhibitionFormState;
  const coverImage = watch('cover');

  const { generateImageKey } = useUploadImageS3();
  const { compressImage } = useImageCompression();
  const [cover, setCover] = useState(coverImage || '');

  const handleUploadImage = async (e) => {
    const file = e?.target?.files[0];
    const imageKey = generateImageKey(file);

    const compressedFile = await compressImage(file);
    const url = URL.createObjectURL(compressedFile);

    const data = {
      uploadFile: compressedFile,
      imageKey,
    };

    setCover(url);
    setValue('coverUploadFile', data);
  };

  return (
    <div>
      <BoxSection heading={t('cover')}>
        {cover && (
          <img
            src={cover}
            className={classes.reviewImgCover}
          />
        )}
        <UploadImage
          onUploadImage={handleUploadImage}
          accept=".png, .jpeg, .jpg"
          uploadWrapStyle={clsx(classes.wrapSmallUpload)}
        >
          <FileUploadOutlinedIcon className={classes.iconAddImage} />
          <Text className={classes.textUploadMedia}>{t('upload')}</Text>
        </UploadImage>
        <Text className={classes.textRecommend}>{t('we_recommend_you_to_portrait')}</Text>
      </BoxSection>

      <BoxSection
        heading={t('Preface')}
        wrapperStyle={classes.wrapperBox}
      >
        <TextArea
          {...register('preface')}
          value={watch('preface')}
          textMaxLength={6000}
          placeholder={t('write_your_description_about_the_exhibition')}
          textAreaStyle={classes.textArea}
          helperText={formState?.errors.preface?.message}
          error={!!formState?.errors.preface}
        />
      </BoxSection>
    </div>
  );
};

export default ExhibitionDetailLeft;
