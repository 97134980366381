import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import ImageCustom from 'commons/ImageCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import Routes from 'utils/Route';
import { axiosGet } from 'utils/apis/axios';

import useStyles from './styles';

const SpecialWorks = ({ title, userUuid }) => {
  const classes = useStyles();
  const [artwork, setArtwork] = useState({});

  useEffect(() => {
    const getArtWork = async () => {
      const { success, data } = await axiosGet(API.ARTWORK.LIST_SPECIAL_ARTWORK, {
        params: {
          user_uuid: userUuid,
        },
      });
      if (success) {
        setArtwork(data);
      }
    };

    getArtWork();
  }, [userUuid]);

  if (!artwork?.length) {
    return null;
  }

  return (
    <Box className={classes.wrapBox}>
      <Text
        fontWeightBold
        type="xl"
      >
        {title}
      </Text>
      <div className={classes.wrapListArtwork}>
        <Grid
          container
          spacing={3}
        >
          {artwork.map((item) => (
            <Grid
              key={item?.id}
              item
              xs={12}
              lg={3}
            >
              <Link to={Routes.ArtworkDetail(item?.id)}>
                <ImageCustom
                  src={item?.images[0]}
                  imageStyle={classes.image}
                  loadingStyle={classes.imageLoading}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default SpecialWorks;
