import clsx from 'clsx';
import React from 'react';

import Image from 'commons/Image';

import CollectionPopover from '../CollectionPopover';
import useStyles from './styles';

const ImageSection = ({ artworks, collectionUuid, isOwner, isPublicCollection }) => {
  const classes = useStyles();

  const [artwork1, artwork2, artwork3] = artworks || [];

  const getFirstImage = (artwork) => artwork?.images?.[0];

  return (
    <div className={classes.container}>
      <div className={classes.mainImageBlock}>
        <Image
          src={getFirstImage(artwork1)}
          className={clsx(classes.image, { [classes.noneImage]: !getFirstImage(artwork1) })}
        />
      </div>

      <div className={classes.sideImageBlock}>
        <Image
          src={getFirstImage(artwork2)}
          className={clsx(classes.image, { [classes.noneImage]: !getFirstImage(artwork2) })}
        />
      </div>

      <div className={classes.sideImageBlock}>
        <Image
          src={getFirstImage(artwork3)}
          className={clsx(classes.image, { [classes.noneImage]: !getFirstImage(artwork3) })}
        />
      </div>

      {isOwner && (
        <CollectionPopover
          dotStyle={classes.dotHover}
          collectionUuid={collectionUuid}
          isPublicCollection={isPublicCollection}
        />
      )}
    </div>
  );
};

export default ImageSection;
