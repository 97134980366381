import moment from 'moment';
import * as yup from 'yup';

export const isValidEmailAddress = (emailAddress) => {
  return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/i.test(emailAddress);
};

export const isValidPhoneNumber = (phoneNumber) => {
  return /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/.test(phoneNumber);
};

export const isValidPassword = (password) => {
  const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
  return regexPassword?.test(String(password));
};

export const isValidConfirmPassword = (password1, password2) => {
  return password1 === password2;
};

export const isFieldValid = (value, isValidFormat) => {
  return value && !isValidFormat;
};

export const isValidURL = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(url);
};

export const isValidYear = (value) => {
  const inputValue = parseInt(value, 10);
  const currentYear = new Date().getFullYear();
  return !isNaN(inputValue) && inputValue >= 1000 && inputValue <= currentYear;
};

export const phoneRegExp = /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/;
export const onlyLetterRegExp = /^[\p{L}\s]*$/u;
export const email_or_phone_number =
  // eslint-disable-next-line no-useless-escape
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

export const isDataEqual = (data1, data2) => {
  return JSON.stringify(data1) === JSON.stringify(data2);
};

export const isValidSizeInput = (value) => {
  return /^\d+(\.\d{0,2})?$/.test(value);
};

export const validateArtworkStepTwo = (params) => {
  const {
    t,
    listImage,
    status,
    title,
    selectYear,
    period,
    medium,
    selectedConfirmnOption,
    piece,
    size,
    totalEdition,
    isCollectorOrGalleryOwner,
    selectedArtist,
    isUpdate = false,
    imageKey,
    style,
    nameUser,
    needValidateArtist,
    isAskPrice,
    contact,
    editionNumber,
  } = params;

  const errors = [];
  const inputDate = new Date(selectYear);
  const currentDate = new Date();

  if (isUpdate ? !imageKey?.imageKeyMain && !listImage[0]?.main : listImage.length === 0) {
    errors.push(t(isUpdate ? 'please_select_main_image' : 'image_list_is_empty'));
  }
  if (!status) {
    errors.push(t('status_is_required'));
  }
  if (isAskPrice && !contact & !isCollectorOrGalleryOwner) {
    errors.push(t('please_enter_your_contact'));
  }
  if (!title) {
    errors.push(t('title_is_required'));
  }
  if (needValidateArtist && isCollectorOrGalleryOwner && !selectedArtist && !nameUser) {
    errors.push(t('please_select_the_artist'));
  }
  if (!selectYear && !period) {
    errors.push(t('creation_year_is_required'));
  }
  if (!!selectYear && inputDate > currentDate) {
    errors.push(t('selecting_creation_year_not_be_greater_than_current_year'));
  }
  if (!medium) {
    errors.push(t('medium_is_required'));
  }
  if (Object.keys(selectedConfirmnOption || {})?.length <= 0) {
    errors.push(t('please_select_your_artwork_has_more_than_one_section'));
  }
  if (!totalEdition) {
    errors.push(t('total_edition_number_is_required'));
  }
  if (totalEdition > 20) {
    errors.push(t('total_edition_number_not_greater_than'));
  }
  if (!piece || piece > 10) {
    errors.push(t('please_input_total_sections'));
  }
  if (!size?.height) {
    errors.push(t('height_is_required'));
  }
  if (!size?.width) {
    errors.push(t('width_is_required'));
  }
  if (medium && medium?.name?.length > 100) {
    errors.push(t('medium_max_length'));
  }
  if (style && style?.name?.length > 100) {
    errors.push(t('style_max_length'));
  }
  if (isCollectorOrGalleryOwner && !editionNumber) {
    errors.push(t('edition_number_is_required'));
  }
  if (editionNumber > totalEdition) {
    errors.push(t('edition_number_exceeds_total'));
  }

  return errors;
};

export const validateQuicklyUpdateArtwork = (
  t,
  title,
  size,
  selectYear,
  period,
  medium,
  canEditArtwork,
) => {
  const errors = [];

  if (canEditArtwork && !title) {
    errors.push(t('title_is_required'));
  }
  if (canEditArtwork && !selectYear && !period) {
    errors.push(t('creation_year_is_required'));
  }
  if (canEditArtwork && !size?.height) {
    errors.push(t('height_is_required'));
  }
  if (canEditArtwork && !size?.width) {
    errors.push(t('width_is_required'));
  }
  if (canEditArtwork && !medium) {
    errors.push(t('medium_is_required'));
  }
  if (canEditArtwork && medium && medium?.name?.length > 100) {
    errors.push(t('medium_max_length'));
  }
  return errors;
};

export const isUUID = (string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(string);
};

export const groupSchema = yup.object().shape({
  groups: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .required('Title is required')
        .test('unique-title', 'Title must be unique', function (value, context) {
          const { from } = context;
          const groups = from[1].value.groups;
          const count = groups.filter((item) => item.title === value).length;
          return count <= 1;
        }),
      artworks: yup.array().min(1, 'At least one artwork is required'),
    }),
  ),
});

export const createExhibitionSchema = yup.object().shape({
  preface: yup.string().max(6000, 'Preface must not exceed 6000 characters'),
  exhibitionTitle: yup
    .string()
    .max(100, 'Exhibition title must not exceed 100 characters')
    .required('Exhibition title is required'),
  dateStart: yup
    .date()
    .transform((value, originalValue) => {
      return moment(originalValue, 'DD-MM-YYYY', true).isValid()
        ? moment(originalValue, 'DD-MM-YYYY').toDate()
        : new Date('');
    })
    .typeError('Date start must be a valid date in DD-MM-YYYY format')
    .required('Date start is required'),
  dateEnd: yup
    .date()
    .transform((value, originalValue) => {
      return moment(originalValue, 'DD-MM-YYYY', true).isValid()
        ? moment(originalValue, 'DD-MM-YYYY').toDate()
        : new Date('');
    })
    .typeError('Date end must be a valid date in DD-MM-YYYY format')
    .required('Date end is required')
    .min(yup.ref('dateStart'), 'Date end must be after or equal to start date'),
  address: yup.string().max(100, 'Address must not exceed 100 characters'),
  organizerName: yup.string().max(100, 'Organizer name must not exceed 100 characters'),
  typeOfEvent: yup.object().nullable(),
  coverUploadFile: yup.object(),
  isPublic: yup.boolean(),
  isDraft: yup.boolean(),
});
