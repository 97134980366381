import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    background: Colors.White,
    paddingBottom: 142,
  },
  wrapContent: {
    paddingTop: 24,
  },
}));

export default useStyles;
