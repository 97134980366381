import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 40,
    paddingBottom: 24,
    borderBottom: `1px solid ${Colors.Grey3}`,
    '&:first-child': {
      marginTop: 0,
    },
  },
}));

export default useStyles;
