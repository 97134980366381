import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  imageContainer: {
    position: 'relative',
    cursor: 'pointer',
  },
  image: {
    height: 145,
    width: '100%',
    objectFit: 'cover',
  },
  closeIcon: {
    '&.MuiSvgIcon-root': {
      position: 'absolute',
      right: 2,
      top: 2,
      color: 'white',
    },
  },
}));

export default useStyles;
