import { DatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import Text from 'commons/Text';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';

import useStyles from './style';

const DayMonthYearSelector = ({
  title,
  onChangeDate,
  selectedDate,
  disabled,
  isRequired,
  error,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapYearSelector}>
      <Text
        type="sm"
        mb={4}
      >
        {title}
        {isRequired && <span className={classes.require}>*</span>}
      </Text>
      <DatePicker
        value={selectedDate ? moment(selectedDate, 'DD/MM/YYYY') : null}
        format="DD/MM/YYYY"
        openTo="day"
        views={['year', 'month', 'day']}
        onChange={(newValue) => onChangeDate(newValue?.toDate() || null)}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        slotProps={{
          textField: {
            inputProps: {
              style: { padding: 11 },
            },
            error,
            helperText,
          },
        }}
        disabled={disabled}
        className={clsx(classes.datePicker, {
          [classes.datePickerDisable]: disabled,
        })}
      />
    </div>
  );
};
export default DayMonthYearSelector;
