import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import GridLayout from 'layouts/GridLayout';

import ExhibitionDetailLeft from './components/ExhibitionDetailLeft';
import ExhibitionDetailRight from './components/ExhibitionDetailRight';
import useStyles from './styles';

const ExhibitionStepOne = ({ onNextStep, exhibitionFormState, step }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Text
        type="2xl"
        fontWeightBold
        variant="h1"
      >
        {t('exhibition_information')}
      </Text>
      <Text mt={10}>{t('please_fill_in_all_information')}</Text>
      <GridLayout
        spacing={2}
        gridWrapStyle={classes.gridWrap}
      >
        <ExhibitionDetailLeft exhibitionFormState={exhibitionFormState} />
        <ExhibitionDetailRight
          onNextStep={onNextStep}
          exhibitionFormState={exhibitionFormState}
          step={step}
        />
      </GridLayout>
    </>
  );
};

export default ExhibitionStepOne;
