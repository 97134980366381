import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapSmallUpload: {
    width: '20%',
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${Colors.Primary}`,
    marginTop: 16,
    marginRight: 38,
  },
  iconAddImage: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  textUploadMedia: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 8,
  },
  textRecommend: {
    marginTop: 16,
    fontSize: 12,
  },
  wrapperBox: {
    marginTop: 20,
  },
  textArea: {
    minHeight: 600,
  },
  reviewImgCover: {
    marginTop: 10,
    width: 162,
    height: 231,
    display: 'block',
    objectFit: 'cover',
  },
}));

export default useStyles;
