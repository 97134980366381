import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapDateTime: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
