import { MenuItem, MenuList } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const ArtworkMenuList = ({ groups }) => {
  const classes = useStyles();

  return (
    <MenuList>
      {groups?.map((group) => (
        <MenuItem key={group?.id}>
          <div className={classes.menuItemWrap}>
            <Text fontWeightMedium>{group?.title}</Text>
            <Text type="sm">{group?.artworks?.length} artworks</Text>
          </div>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default ArtworkMenuList;
