import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, createSearchParams } from 'react-router-dom';

import Text from 'commons/Text';
import Routes from 'utils/Route';

import useStyles from './styles';

const GalleryArtist = ({ listArtist, tokenParam }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { artistId } = useParams();

  const handleRouter = (artist) => {
    const url = Routes.UserArtistArtwork(artistId, artist?.uuid || artist?.id);

    if (tokenParam) {
      const searchParams = createSearchParams({ token: tokenParam });
      navigate({
        pathname: url,
        search: searchParams.toString(),
      });
    } else {
      navigate(url);
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ mt: 2 }}
      >
        {listArtist.map((artist, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={index}
            sx={{ mb: 2 }}
          >
            <div onClick={() => handleRouter(artist)}>
              <Text fontWeightMedium>
                {artist?.artist_name}{' '}
                {artist?.year_of_birth ? `(B.${artist.year_of_birth})` : '(N/A)'}
              </Text>
              <Text
                type="sm"
                className={classes.textArtworkCount}
              >
                {artist?.artwork_count} {t('artworks')}
              </Text>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default GalleryArtist;
